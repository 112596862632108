const Index = () =>
  import( /* webpackChunkName: "issues" */ "@/app_modules/surveyedItems/index.vue");

export const routes = [{
  path: "/surveyed_items",
  component: {
      template: '<router-view></router-view>'
  },
  meta: {
      basename: "surveyed_items",
      featureFlag: "surveyed_items",
      roles: {
          isAdmin: true,
          isContributor: true
      }
  },
  children: [{
      path: "",
      name: "surveyed_items",
      component: Index,
      meta: {
          basename: "surveyed_items",
          featureFlag: "surveyed_items",
          roles: {
              isAdmin: true,
              isContributor: true
          }
      }
  }]
}];
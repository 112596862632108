import { store, AppModel, getStoreState } from "@/store/app.store";
import { join, propertyResolve, unjoin, fillArray } from "./UTILS";

export { localizationStoreActions, localizationStoreGetters } from "./localization.store";
export { boundariesStoreActions, boundariesStoreGetters } from "./boundaries.store";
import { uiStoreActions, uiStoreGetters } from "./ui.store";
import { surveysStoreActions, surveysStoreGetters } from "./surveys.store";
import { tasksStoreActions, tasksStoreGetters } from "./tasks.store";
import { usersStoreActions, usersStoreGetters } from "./users.store";
import { issuesStoreActions, issuesStoreGetters } from "./issues.store";
import { mediasStoreActions, mediasStoreGetters } from "./medias.store";
import { layerDatasStoreActions, layerDatasStoreGetters } from "./layerdata.store";
import { layersStoreActions, layersStoreGetters } from "./layers.store";
import { trackingStoreActions, trackingStoreGetters } from "./tracking.store";
import { createSurveyStoreActions, createSurveyStoreGetters } from "./createsurvey.store";

export {
  store,
  AppModel,
  join,
  fillArray,
  getStoreState,
  propertyResolve,
  unjoin,
  uiStoreActions,
  uiStoreGetters,
  surveysStoreActions,
  surveysStoreGetters,
  tasksStoreActions,
  tasksStoreGetters,
  usersStoreActions,
  usersStoreGetters,
  issuesStoreActions,
  issuesStoreGetters,
  mediasStoreActions,
  mediasStoreGetters,
  layerDatasStoreActions,
  layerDatasStoreGetters,
  layersStoreActions,
  layersStoreGetters,
  trackingStoreActions,
  trackingStoreGetters,
  createSurveyStoreActions,
  createSurveyStoreGetters,
  // Intersections Interfaces
};


import { baseRestService } from "./_base";
import { boundariesStoreActions, surveysStoreActions } from "@/store";
import { Config } from "@/config";
import { AlertHelper } from "./_base/AlertHelper";
import { localizeFunction } from "@/filters/localize";

class EngineService extends baseRestService {

  constructor() {
    super();
    this.baseUrl = Config.appServerUrl;
  }

  public async CreateSurvey(data: server.survey): Promise<void> {
    return await this.PostCatch(`/api/engine/survey/create`, data);
  }

  public async CreateBoundaries(surveyid: string, data: server.boundary[]): Promise<void> {
    return await this.PostCatch(`/api/engine/survey/create/${surveyid}/boundaries`, data);
  }

  public async CloneBoundaries(oldSurveyid: string, newSurveyid: string): Promise<void> {
    return await this.PostCatch(`/api/engine/survey/clone/${oldSurveyid}/boundaries/${newSurveyid}`, null);
  }

  public async CreateLayer(surveyid: string, layer: server.layerFull, notDelete: any[]): Promise<void> {
    return await this.PostCatch(`/api/engine/survey/create/${surveyid}/layer`, {
      layer,
      notDelete,
    });
  }

  public async CreateLayerDefinitions(surveyid: string, layerId: string, data: server.layerDefinitionFull[]): Promise<void> {
    return await this.PostCatch(`/api/engine/survey/create/${surveyid}/layer/${layerId}/layer-definitions`, data);
  }

  public async CreateLayerValidationRules(surveyid: string, layerId: string, data: server.layerDefinitionValidationRule[]): Promise<void> {
    return await this.PostCatch(`/api/engine/survey/create/${surveyid}/layer/${layerId}/validation-rules`, data);
  }

  public async CreateLayerStatDefinitions(surveyid: string, layerId: string, data: server.statDefinition[]): Promise<void> {
    return await this.PostCatch(`/api/engine/survey/create/${surveyid}/layer/${layerId}/stats-definitions`, data);
  }

  public async CreateLayerQualityDefinitions(surveyid: string, data: server.qualityDefinition[]): Promise<void> {
    return await this.PostCatch(`/api/engine/survey/create/${surveyid}/quality-definitions`, data);
  }

  public async CreateLayerDatas(surveyid: string, layerId: string, data: server.layerData[]): Promise<void> {
    return await this.PostCatch(`/api/engine/survey/create/${surveyid}/layer/${layerId}/layer-datas`, data);
  }

  public async CreateLayerDatasFromFile(surveyid: string, layerId: string, file: File): Promise<void> {
    return await this.PostFileCatch(`/api/engine/survey/create/${surveyid}/layer/${layerId}/layer-datas/file`, file);
  }
  
  public async ConsolidateLayerDatasFromQueue(surveyid: string): Promise<void> {
    return await this.Get(`/api/engine/survey/create/${surveyid}/layer-datas-consolidate-queue`);
  }
  
  public async CompleteSurvey(surveyid: string): Promise<void> {
    return await this.Put(`/api/engine/survey/complete/${surveyid}`, null);
  }

  public async ClearFailSurvey(surveyid: string): Promise<void> {
    const result = await this.Delete(`/api/engine/survey/clear/${surveyid}`);
    if (result) { surveysStoreActions.deleteSurvey(surveyid); }
    return;
  }

  public async PostCatch(uri, data: any): Promise<any> {
    const result = await this.post(uri, data, null, true);
    if (!result) {
      const resultFailed = localizeFunction("PostCatch_result_failed");
      AlertHelper.showError("an_error_has_occurred", resultFailed, 5000);
      throw resultFailed;
    }

    if (result.status === 200) {
      return result.data;
    } else {
      AlertHelper.showError("an_error_has_occurred", result.data.message, 5000);
      throw result.data.message;
    }
    return result;
  }


  public async PostFileCatch(uri, file: File): Promise<any> {
    const result = await this.postFile(uri, file);
    if (!result) {
      const resultFailed = localizeFunction("PostCatch_result_failed");
      AlertHelper.showError("an_error_has_occurred", resultFailed, 5000);
      throw resultFailed;
    }

    if (result.status === 200) {
      return result.data;
    } else {
      AlertHelper.showError("an_error_has_occurred", result.data.message, 5000);
      throw result.data.message;
    }
    return result;
  }

}

export const engineService = new EngineService();

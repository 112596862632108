import { Config } from "@/config";
import { baseRestService, AuthToken, sharedInterceptors } from "./_base/baseRestService";
import { issuesStoreActions } from "@/store/issues.store";
import { Hub, INotificationMessage } from "./hub";
import { MessageService, Factory } from "./_base";

class IssuesService extends baseRestService {

  constructor() {
    super();
    this.baseUrl = Config.appServerUrl;

    // Ricevo le notifiche degli avanzamenti dei task
    Hub.Current.subscribeToMessage((client) => {
      client.issueUpdate = async (message: INotificationMessage) => {
        const issue = await this.GetIssue(message.surveyId, message.id);
        if (issue) {
          Factory.Get(MessageService).publishToChannel("update-issues");
        }
      };
    });
  }

  public async CountIssues(surveyid: string, boundaryId?: string, issueId?: string, userName?: string, state?: string, priority?: string): Promise<number> {
    return await this.Get<number>(`/api/survey/${surveyid}/issues/count`, { boundaryId, issueId, userName, state, priority });
  }

  public async GetIssues(surveyid: string, boundaryId?: string, issueId?: string, userName?: string, state?: string, priority?: string, skip?: number, take?: number): Promise<server.issue[]> {
    const result = await this.Get<server.issue[]>(`/api/survey/${surveyid}/issues`, { boundaryId, issueId, userName, state, priority, skip, take });
    issuesStoreActions.setIssues(result);
    return result;
  }

  public async GetIssuesQuery(surveyid: string, boundaryId?: string, issueId?: string, boundariesId?: string[], skip?: number, take?: number): Promise<server.issue[]> {
    const result = await this.Post<server.issue[]>(`/api/survey/${surveyid}/issues/query`, { boundaryId, issueId, boundariesId, skip, take });
    issuesStoreActions.setIssues(result);
    return result;
  }

  public async GetIssue(surveyid: string, id: string): Promise<server.issue> {
    const result = await this.Get<server.issue>(`/api/survey/${surveyid}/issues/${id}`);
    issuesStoreActions.setIssues([result]);
    return result;
  }

  public async PostIssue(surveyid: string, issue: server.issue): Promise<server.issue> {
    const result = await this.Post<server.issue>(`/api/survey/${surveyid}/issues`, issue);
    issuesStoreActions.setIssues([result]);
    return result;
  }

  public async PutIssue(surveyid: string, issueId: string, issue: server.issue): Promise<server.issue> {
    const result = await this.Put<server.issue>(`/api/survey/${surveyid}/issues/${issueId}`, issue);
    issuesStoreActions.setIssues([result]);
    return result;
  }

  public async DeleteIssue(surveyid: string, boundaryId?: string, id?: string): Promise<server.issue> {
    const result = await this.Delete<server.issue>(`/api/survey/${surveyid}/issues/${id}`, { surveyid, boundaryId, id });
    issuesStoreActions.deleteIssue(id);
    return result;
  }
}

export const issuesService = new IssuesService();

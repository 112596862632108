
declare var DEVELOPMENT: string;
declare var API_URL: string;
declare var AUTH_URL: string;
declare var CLIENT_ID: string;

export class Config {

  public static arcgisPortal: string = "https://www.arcgis.com/sharing/rest/portals/self?f=json";
  public static clientSideUrl: string = window.location.origin;
  public static authorizationServerUrl: string = AUTH_URL;

  public static appServerUrl: string = API_URL;
  public static appSignalrHub: string = API_URL + "/signalr";
  public static storageImageServerUrl: string = API_URL;
  public static localizationServerUrl: string = API_URL;
  public static subscriptionServerUrl: string = AUTH_URL;

  public static signinCallbackRoute: string = "/auth_callback.html";
  public static maxZoomForMapScreenshots: number = 18;

  public static DB_NAME = "offline_tile_store";   // Sets the database name.
  public static DB_OBJECTSTORE_NAME = "tilepath"; // Represents an object store that allows access to a set of data in the IndexedDB database

  public static languages = {
    current: "en",
    formatter: {
      en: {
        icon: "/assets/imgs/flags/en.png",
        text: "English",
      },
      it: {
        icon: "/assets/imgs/flags/it.png",
        text: "Italiano",
      },
      fr: {
        icon: "/assets/imgs/flags/fr.png",
        text: "Français",
      },
      pt: {
        icon: "/assets/imgs/flags/pt.png",
        text: "Português",
      },
      es: {
        icon: "/assets/imgs/flags/es.png",
        text: "Español",
      },
      tr: {
        icon: "/assets/imgs/flags/tr.png",
        text: "Türk",
      },
      sq: {
        icon: "/assets/imgs/flags/al.png",
        text: "Shqip",
      },
      ro: {
        icon: "/assets/imgs/flags/ro.png",
        text: "Românǎ",
      },
    },
  };

  public static defaultLanguage: string = "en";

  public static checkSessionInterval: any = 30000;
  public static renewSessionTimeLimit: any = 1000;
  public static CLIENT_ID: string = CLIENT_ID;
  public static openidScope: string = "openid profile email";

}

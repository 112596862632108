import { Esri } from "@/esriMap";
import { propertyResolve } from "..";
import { layersStoreGetters } from "../layers.store";
import { layersService } from "@/services";

export class SurveyIntersection implements ISurvey {
  private static pointConstructor: __esri.PointConstructor = null;

  public id: any;
  public name: string;
  public description: string;
  state: 'Pending' | 'Processing' | 'Draft' | 'Published' | 'Fail' | 'ToBeRemoved' | 'Removed';
  public date: Date;
  public startDade: Date;
  public endDate?: Date;
  public latitude: number;
  public longitude: number;
  public completed: boolean;
  public jsonData: server.monitoringStatConfiguration[];

  constructor() {
    this.Initialize();
  }

  public layers(): server.layerFull[] {
    return propertyResolve(layersStoreGetters.getLayers(this.id), () => layersService.GetLayers(this.id), this.id);
  }

  public geometryESRI() {
    if (SurveyIntersection.pointConstructor && this.latitude && this.longitude) {
      return new SurveyIntersection.pointConstructor({
        longitude: this.longitude,
        latitude: this.latitude,
        spatialReference: { wkid: 4326 },
      } as __esri.PointProperties) as any;
    }
    return null;
  }

  private async Initialize() {
    SurveyIntersection.pointConstructor = await Esri.Geometry.PointConstructor();
  }
}

export const surveyIntersection = new SurveyIntersection();

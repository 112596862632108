import {
  CreateActionsHandler,
  CreateGettersHandler,
  fillArray,
  join,
} from "./UTILS";
import { boundaryIntersection } from "./intersections/boundaries.intersection";
import { isUndefined } from "util";
import { getStoreState, usersStoreGetters } from ".";

export interface BoundariesStoreModel {
  _boundaries: Map<string, IBoundary>;
  boundaries: IBoundary[];
  _boundariesAutocomplete: Map<string, server.boundary>;
  boundariesAutocomplete: server.boundary[];
  _usersInBoundaries: Map<string, server.userInBoundary>;
  usersInBoundaries: server.userInBoundary[];
  boundaryTree: Map<string, IBoundary[]>;
}

interface BoundariesStoreGetters {
  getBoundaries(parentId: string, boundaryId?: string, code?: string, description?: string, userId?: string): IBoundary[];
  filterBoundaries(boundaryId?: string, code?: string, description?: string, userId?: string): IBoundary[];
  getBoundary(boundaryId: string): IBoundary;
  getBoundariesForUser(userId: string): server.userInBoundary[];
  getUsersInBoundary(boundaryId: string): server.userInBoundary[];
  getBoundariesAutocomplete(userId?: string, boundariesId?: string[]): server.autocomplete[];
  getBoundaryAutocomplete(boundaryId: string);
  getUsersAutocomplete(boundaryId?: string, usersId?: string[]): server.autocomplete[];
  getBoundaryTree(boundaryId: string): IBoundary[];
}
interface BoundariesStoreActions {
  setBoundaries(data: server.boundary[]);
  setBoundariesAutocomplete(data: server.boundary[]);
  setBoundaryTree(boundaryId: string, data: server.boundary[]);
  cleanBoundaries();
  deleteBoundary(boundaryId: string);

  setUsersInBoundaries(data: server.userInBoundary[]);
  cleanUsersInBoundaries();
  deleteUserInBoundary(data: server.userInBoundary);
}

export const boundariesStore = {
  PREFIX: "boundaries",
  namespaced: true,
  state: {
    _boundaries: new Map<string, server.boundary>(),
    boundaries: [],
    _boundariesAutocomplete: new Map<string, server.boundary>(),
    boundariesAutocomplete: [],
    _usersInBoundaries: new Map<string, server.userInBoundary>(),
    usersInBoundaries: [],
    boundaryTree: new Map<string, IBoundary[]>(),
  } as BoundariesStoreModel,
  getters: {
    getBoundaries: (state: BoundariesStoreModel) => (parentId: string, boundaryId: string = null, code: string = null, description: string = null, userId: string = null) => {
      let userBoundaryIds: string[] = [];
      if (userId) {
        userBoundaryIds = (state.usersInBoundaries || [] as server.userInBoundary[]).filter((i) => i.userId === userId).map((m) => m.boundaryId);
      }

      return state.boundaries
        .filter(
          (b) => b.parentId === parentId
            && (!boundaryId || (boundaryId && b.id === boundaryId))
            && (!code || (code && (b.code || "").toLowerCase().indexOf(code.toLowerCase()) >= 0))
            && (!description || (description && (b.description || "").toLowerCase().indexOf(description.toLowerCase()) >= 0))
            && (!userId || (userId && userBoundaryIds.indexOf(b.id) >= 0)))
        .sort((a, b) => {
          return a.code.localeCompare(b.code);
        });
    },
    filterBoundaries: (state: BoundariesStoreModel) => (boundaryId: string = null, code: string = null, description: string = null, userId: string = null) => {
      let userBoundaryIds: string[] = [];
      if (userId) {
        userBoundaryIds = (state.usersInBoundaries || [] as server.userInBoundary[]).filter((i) => i.userId === userId).map((m) => m.boundaryId);
      }

      return state.boundaries
        .filter(
          (b) => (!boundaryId || (boundaryId && b.id === boundaryId))
            && (!code || (code && (b.code || "").toLowerCase().indexOf(code.toLowerCase()) >= 0))
            && (!description || (description && (b.description || "").toLowerCase().indexOf(description.toLowerCase()) >= 0))
            && (!userId || (userId && userBoundaryIds.indexOf(b.id) >= 0)))
        .sort((a, b) => {
          return a.code.localeCompare(b.code);
        });
    },
    getBoundary: (state: BoundariesStoreModel) => (boundaryId: string) => {
      return state.boundaries.find((b) => b.id === boundaryId);
    },
    getBoundariesForUser: (state: BoundariesStoreModel) => (userId: string) => {
      return state.usersInBoundaries.filter((i) => i.userId === userId);
    },
    getUsersInBoundary: (state: BoundariesStoreModel) => (boundaryId: string) => {
      return state.usersInBoundaries.filter((a) => a.boundaryId === boundaryId);
    },
    getBoundariesAutocomplete: (state: BoundariesStoreModel) => (userId?: string, boundariesId?: string[]): server.autocomplete[] => {
      const boundaries = state.boundariesAutocomplete.filter((x) => !boundariesId || boundariesId.indexOf(x.id) >= 0).map((m) => {
        return {
          key: m.id,
          value: m.code,
          description: m.code,
        } as server.autocomplete;
      });

      if (!userId) { return boundaries; }

      const boundaryIds = state.usersInBoundaries
        .filter((i) => i.userId === userId)
        .map((x) => x.boundaryId);

      return boundaries.filter(
        (f) => boundaryIds.indexOf(f.key) >= 0,
      );
    },
    getBoundaryAutocomplete: (state: BoundariesStoreModel) => (boundaryId: string): server.boundary => {
      return state.boundariesAutocomplete.find((x) => x.id === boundaryId);
    },
    getUsersAutocomplete: (state: BoundariesStoreModel) => (boundaryId?: string, usersId?: string[]): server.autocomplete[] => {
      let users = usersStoreGetters.getUsersAutocomplete(usersId, true);
      if (boundaryId) {
        const usersIds = state.usersInBoundaries.filter((i) => i.boundaryId === boundaryId)
          .map((x) => x.userId)
          .filter((item: string, pos, collection: string[]) => {
            return collection.indexOf(item) === pos;
          });
        users = users.filter((f) => usersIds.indexOf(f.key) >= 0);
      }
      return users;
    },
    getBoundaryTree: (state: BoundariesStoreModel) => (boundaryId): IBoundary[] => {
      return state.boundaryTree.get(boundaryId);
    },
  },
  mutations: {
    setBoundaries(state: BoundariesStoreModel, data: server.boundary[]) {
      if (!data || data.length <= 0) { return; }
      data.filter((x) => !!x).forEach((i) => {
        if (!state._boundaries.has(i.id)) { state._boundaries.set(i.id, join(i)(boundaryIntersection)); }
      });
      fillArray(state.boundaries, state._boundaries.values());
    },
    setBoundariesAutocomplete(state: BoundariesStoreModel, data: server.boundary[]) {
      if (!data || data.length <= 0) { return; }
      data.filter((x) => !!x).forEach((i) => { state._boundariesAutocomplete.set(i.id, i); });
      fillArray(state.boundariesAutocomplete, state._boundariesAutocomplete.values());
    },
    setBoundaryTree(state: BoundariesStoreModel, data: {rootId: string, boundaries: server.boundary[]}) {
      state.boundaryTree.set(data.rootId, data.boundaries.map(m => join(m)(boundaryIntersection)));
    },
    cleanBoundaries(state: BoundariesStoreModel) {
      state._boundaries.clear();
      state.boundaries.splice(0, state.boundaries.length);
    },
    deleteBoundary(state: BoundariesStoreModel, boundaryId: string) {
      state._boundaries.delete(boundaryId);
      fillArray(state.boundaries, state._boundaries.values());

      state._boundariesAutocomplete.delete(boundaryId);
      fillArray(state.boundariesAutocomplete, state._boundariesAutocomplete.values());
    },
    setUsersInBoundaries(state: BoundariesStoreModel, data: server.userInBoundary[]) {
      if (!data || data.length <= 0) {
        return;
      }
      data.forEach((i) => {
        state._usersInBoundaries.set(`${i.boundaryId}_${i.userId}`, i);
      });
      fillArray(state.usersInBoundaries, state._usersInBoundaries.values());
    },
    cleanUsersInBoundaries(state: BoundariesStoreModel) {
      state._usersInBoundaries.clear();
      state.usersInBoundaries.splice(0, state.usersInBoundaries.length); // clean
    },
    deleteUserInBoundary(state: BoundariesStoreModel, data: server.userInBoundary) {
      state._usersInBoundaries.delete(`${data.boundaryId}_${data.userId}`);
      fillArray(state.usersInBoundaries, state._usersInBoundaries.values());
    },
  },
  actions: {
    setBoundaries(context, data: server.boundary[]) {
      context.commit("setBoundaries", data);
    },
    setBoundariesAutocomplete(context, data: server.autocomplete[]) {
      context.commit("setBoundariesAutocomplete", data);
    },
    cleanBoundaries(context) {
      context.commit("cleanBoundaries");
    },
    deleteBoundary(context, boundaryId: string) {
      context.commit("deleteBoundary", boundaryId);
    },
    setUsersInBoundaries(context, data: server.userInBoundary[]) {
      context.commit("setUsersInBoundaries", data);
    },
    cleanUsersInBoundaries(context) {
      context.commit("cleanUsersInBoundaries");
    },
    deleteUserInBoundary(context, data: server.userInBoundary) {
      context.commit("deleteUserInBoundary", data);
    },
  },
};

export const boundariesStoreActions = CreateActionsHandler<BoundariesStoreActions>(boundariesStore);
export const boundariesStoreGetters = CreateGettersHandler<BoundariesStoreGetters>(boundariesStore);

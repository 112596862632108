const TasksIndex = () =>
    import ( /* webpackChunkName: "tasks" */ "@/app_modules/tasks/index.vue");

export const routes = [{
    path: "/tasks",
    component: {
        template: '<router-view></router-view>'
    },
    meta: {
        basename: "tasks",
        featureFlag: "tasks",
        roles: {
            isAdmin: true,
            isContributor: true
        }
    },
    children: [{
        path: "",
        name: "tasks",
        component: TasksIndex,
        meta: {
            basename: "tasks",
            featureFlag: "tasks",
            roles: {
                isAdmin: true,
                isContributor: true
            }
        }
    }]
}];
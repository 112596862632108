import { Config } from "@/config";
import Qs from "qs";
import { baseRestService, AuthToken } from "./_base/baseRestService";
import { Deferred } from "./_base/Deferred";

class SecurityService extends baseRestService {
  private static myself = null;

  constructor() {
    super();
    this.baseUrl = Config.authorizationServerUrl;

    this.saveToSessionStorage = false;
    SecurityService.myself = this;
    window.setInterval(this.autoRenewToken, Config.checkSessionInterval);
  }

  public getUserRequestedPath() {
    return window.sessionStorage.getItem("requestedpath");
  }

  public async startSignIn() {
    window.sessionStorage.setItem("requestedpath", window.location.origin);

    const params = Qs.stringify(
      {
        redirect_uri: Config.clientSideUrl + Config.signinCallbackRoute,
        response_type: "id_token token",
        scope: Config.openidScope,
        nonce: new Date().getTime(),
        client_id: Config.CLIENT_ID,
        applicationid: Config.CLIENT_ID,
      },
    );
    window.location.href = Config.authorizationServerUrl + "/connect/authorize?" + params;
  }

  public async Login(username: string, password: string): Promise<boolean> {
    const formdata = Qs.stringify({
      grant_type: "password",
      username,
      password,
      scope: Config.openidScope,
      client_id: Config.CLIENT_ID,
      applicationid: Config.CLIENT_ID,
    });
    const result = await this.http.post(this.baseUrl + "/connect/token", formdata, { headers: { "Content-Type": "application/x-www-form-urlencoded" } });

    if (result.status === 200) {
      if (result.data.expires_in) {
        result.data.expiration_date = new Date(new Date().getTime() + result.data.expires_in * 1000).getTime();
      }
      this.setAuthenticationToken(result.data as AuthToken);
      return true;
    } else {
      this.deleteAuthenticationToken();
      if (this.OnError != null) {
        this.OnError(result);
      }
    }
    return false;
  }

  public async Logout() {
    this.deleteAuthenticationToken();
    window.localStorage.removeItem("userInfo");
    window.localStorage.removeItem("boundaryLayoutConfiguration");
    window.localStorage.removeItem("layoutConfiguration");
    window.localStorage.removeItem("teamLayoutConfiguration");
    window.localStorage.removeItem("current-survey");
    await this.remotelogoff();
    this.startSignIn();
    // window.location.href = Config.authorizationServerUrl + "/Account/Logoff?returnurl=" + Config.clientSideUrl;
  }

  public getUserOrganizationId(): number {
    const agrofId = window.localStorage.getItem("agrofId");

    if (agrofId) {
      return parseInt(agrofId, 10);
    }

    const user = JSON.parse(window.localStorage.getItem("userInfo")) as UserInfo;

    if (user) {
      return user.organization_id;
    }
    return null;
  }

  public getUserId(): number {
    const user = JSON.parse(window.localStorage.getItem("userInfo")) as UserInfo;
    return user == null ? null : user.sub;
  }

  public getUsername(): string {
    const user = JSON.parse(window.localStorage.getItem("userInfo")) as UserInfo;
    return user == null ? null : user.email;
  }

  public async getUserAgroOfIds() {
    return await this.http.get(Config.authorizationServerUrl + "/api/myorganizations", { headers: this.prepareHeaders(true, false) });
  }

  public async getUserInfo() {
    try {
      const resultInfo = await this.get("/api/userinfo");
      window.localStorage.setItem("userInfo", JSON.stringify(resultInfo.data));
    } catch (err) {
      this.Logout();
    }
  }

  public isUserAuthenticated() {
    return window.localStorage.getItem("userInfo") != null;
  }

  public checkAuthenticationResponse() {
    const auth = window.sessionStorage.getItem("authresponse");
    window.sessionStorage.removeItem("authresponse");

    if (auth) {
      const authobj = Qs.parse(auth.substr(1));
      securityService.setUserAuth(authobj);
    }
  }

  private async autoRenewToken() {
    const token = SecurityService.myself.getAuthenticationToken();

    if (token) {
      const now = new Date().getTime();
      const timeleft = token.expiration_date - now;

      SecurityService.myself.checkAuthenticationResponse();
      SecurityService.myself.getUserInfo();
      if (timeleft < Config.renewSessionTimeLimit * 1000) {
        await SecurityService.myself.renewToken(token);
      }
    }
  }

  private setUserAuth(data: any) {
    if (data.expires_in) {
      data.expiration_date = new Date(new Date().getTime() + data.expires_in * 1000).getTime();
    }
    this.setAuthenticationToken(data);
  }

  private async renewTokenUsingRefreshToken(token: AuthToken) {
    // flow using refresh_token
    const formdata = Qs.stringify({
      grant_type: "refresh_token",
      refresh_token: token.refresh_token || token.id_token,
      scope: Config.openidScope,
      client_id: Config.CLIENT_ID,
      applicationid: Config.CLIENT_ID,
    });

    const result = await SecurityService.myself.http.post(SecurityService.myself.baseUrl + "/connect/token", formdata, { headers: { "Content-Type": "application/x-www-form-urlencoded" } });
    if (result.status === 200) {
      if (result.data.expires_in) {
        result.data.expiration_date = new Date(new Date().getTime() + result.data.expires_in * 1000).getTime();
      }

      if (!result.data.refresh_token) {
        result.data.refresh_token = token.refresh_token;
      }
      SecurityService.myself.setAuthenticationToken(result.data as AuthToken);
    }
  }

  private async remotelogoff() {
    const waitDeferred = new Deferred();
    const element = document.createElement("iframe");
    element.id = "__logoff__frame";
    element.style.display = "none";
    document.body.appendChild(element);

    element.addEventListener("load", () => {
      waitDeferred.resolve();
      document.body.removeChild(element);
    });
    element.src = Config.authorizationServerUrl + "/connect/logout";
    await waitDeferred.promise;
  }

  private async renewTokenUsingSilentLogin(token: AuthToken) {
    const params = Qs.stringify(
      {
        redirect_uri: Config.clientSideUrl + Config.signinCallbackRoute,
        response_type: "id_token token",
        scope: Config.openidScope,
        nonce: new Date().getTime(),
        prompt: "none",
        client_id: Config.CLIENT_ID,
        applicationid: Config.CLIENT_ID,
      },
    );

    let element: HTMLIFrameElement = document.getElementById("__login__frame") as HTMLIFrameElement;
    if (!element) {
      element = document.createElement("iframe");
      element.id = "__login__frame";
      element.style.display = "none";
      document.body.appendChild(element);
    }
    element.src = Config.authorizationServerUrl + "/connect/authorize?" + params;
  }

  private async renewToken(token: AuthToken) {
    if (token.refresh_token) {
      await this.renewTokenUsingRefreshToken(token);
    } else {
      await this.renewTokenUsingSilentLogin(token);
    }
  }

}

export const securityService = new SecurityService();

export interface UserInfo {
  sub: number;
  email: string;
  email_verified: boolean;
  organization_id: number;
}

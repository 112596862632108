import Vue from "vue";
import { localizeFunction } from "./localize";

Vue.filter("distance", formatDistance);

function formatDistance(distance, opts = {
  precision: 2,
  numberonly: false,
  unitonly: false,
  longuints: false
}) {
  if (distance < 1000) {
    if (opts.numberonly)
      return distance;
    if (opts.unitonly)
      return localizeFunction(opts.longuints ? "meters" : "m")
    return distance + " " + localizeFunction(opts.longuints ? "meters" : "m")
  } else {
    let m = Math.pow(10, opts.precision)
    let kms = Math.round(distance / 1000 * m) / m;
    if (opts.numberonly)
      return kms;
    if (opts.unitonly)
      return localizeFunction(opts.longuints ? "kilometers" : "Km")
    return kms + " " + localizeFunction(opts.longuints ? "kilometers" : "Km")

  }
}

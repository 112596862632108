import { Config } from "@/config";
import { baseRestService } from "./_base/baseRestService";
import { layersStoreActions } from "@/store/layers.store";

class LayersService extends baseRestService {

  constructor() {
    super();

    this.baseUrl = Config.appServerUrl; 
  }

  public async GetLayers(surveyid: string, skip?: number, take?: number): Promise<server.layerFull[]> {
    let result = await this.Get<server.layerFull[]>(`/api/survey/${surveyid}/layers`, { skip, take, fulldata: true });
    layersStoreActions.setLayers(result);
    return result;
  }

  public async GetLayersNotStored(surveyid: string, skip?: number, take?: number): Promise<server.layerFull[]> {
    return await this.Get<server.layerFull[]>(`/api/survey/${surveyid}/layers`, { skip, take, fulldata: true });
  }

  public async CreateLayers(surveyid: string, data: JSON): Promise<server.layerFull> {
    let result = await this.Post<server.layerFull>(`/api/survey/${surveyid}/layers`, { data });
    layersStoreActions.setLayers([result]);
    return result;
  }

  public async DeleteLayers(surveyid: string, layerId: string): Promise<server.layerFull> {
    let result = await this.Delete<server.layerFull>(`/api/survey/${surveyid}/layers/${layerId}`);
    layersStoreActions.deleteLayer(result.id);
    return result;
  }

  public async GetLayer(surveyid: string, id: string): Promise<server.layerFull> {
    let result = await this.Get<server.layerFull>(`/api/survey/${surveyid}/layers/${id}`);
    layersStoreActions.setLayers([result]);
    return result;
  }

  public async UpdateLayers(surveyid: string, layerId: string,data :server.layerFull): Promise<server.layerFull[]> {
    let result = await this.Put<server.layerFull[]>(`/api/survey/${surveyid}/layers/${layerId}`,data);
    layersStoreActions.setLayers(result);
    return result;
  }


  public async GetLayersFields(surveyid: string,layerId:string, skip?: number, take?: number): Promise<server.layerFull[]> {
    let result = await this.Get<server.layerFull[]>(`/api/survey/${surveyid}/layers/${layerId}/fields`, { skip, take});
    layersStoreActions.setLayers(result);
    return result;
  }

  public async DeleteLayersField(surveyid: string,layerId:string, id?: string): Promise<server.layerFull> {
    let result = await this.Delete<server.layerFull>(`/api/survey/${surveyid}/layers/${layerId}/field/${id}`);
    layersStoreActions.deleteLayer(result.id);
    return result;
  }

  public async GetLayersField(surveyid: string,layerId:string, id?: string): Promise<server.layerFull> {
    let result = await this.Get<server.layerFull>(`/api/survey/${surveyid}/layers/${layerId}/field/${id}`);
    layersStoreActions.setLayers([result]);
    return result;
  }

  public async PostLayersField(surveyid: string,layerId:string, data?: server.layerFull): Promise<server.layerFull[]> {
    let result = await this.Post<server.layerFull[]>(`/api/survey/${surveyid}/layers/${layerId}/field`,data);
    layersStoreActions.setLayers(result);
    return result;
  }

  public async GetLayersFieldRule(surveyid: string,layerId:string, field: string): Promise<server.layerFull[]> {
    let result = await this.Get<server.layerFull[]>(`/api/survey/${surveyid}/layers/${layerId}/field/${field}/rule`);
    layersStoreActions.setLayers(result);
    return result;
  }

  public async PostLayersFieldRule(surveyid: string,layerId:string, field: string,data:server.layerFull): Promise<server.layerFull> {
    let result = await this.Post<server.layerFull>(`/api/survey/${surveyid}/layers/${layerId}/field/${field}/rule`,data);
    layersStoreActions.setLayers([result]);
    return result;
  }

  public async DeleteLayersFieldRule(surveyid: string,layerId:string, field: string,Id:number): Promise<server.layerFull> {
    let result = await this.Delete<server.layerFull>(`/api/survey/${surveyid}/layers/${layerId}/field/${field}/rule/${Id}`);
    layersStoreActions.setLayers([result]);
    return result;
  }

  public async GetLayersFieldRuleId(surveyid: string,layerId:string, field: string,Id:number): Promise<server.layerFull> {
    let result = await this.Get<server.layerFull>(`/api/survey/${surveyid}/layers/${layerId}/field/${field}/rule/${Id}`);
    layersStoreActions.setLayers([result]);
    return result;
  }

  public async PutLayersFieldRuleId(surveyid: string,layerId:string, field: string,Id:number,data:server.layerFull): Promise<server.layerFull> {
    let result = await this.Put<server.layerFull>(`/api/survey/${surveyid}/layers/${layerId}/field/${field}/rule/${Id}`,data);
    layersStoreActions.setLayers([result]);
    return result;
  }
}

export const layersService = new LayersService();

import { CreateActionsHandler, CreateGettersHandler, fillArray } from './UTILS';
import { isContext } from 'vm';
import { usersStoreGetters } from '.';
import { boundariesStoreGetters } from './boundaries.store';

export interface TasksStoreModel {
  _tasks: Map<string, server.task>;
  tasks: server.task[]
}

interface TasksStoreGetters {
  getTasks(boundaryId?: string, userName?: string, state?: string, skip?: number, take?: number): server.task[];
  getTasksBoundariesAutocomplete(userName: string): server.autocomplete[];
  getTasksUsersAutocomplete(boundariesId: string[]): server.autocomplete[];
}

interface TasksStoreActions {
  setTasks(data: server.task[]);
  deleteTask(taskId: string);
}

export const tasksStore = {
  PREFIX: "tasks",
  namespaced: true,
  state: {
    _tasks: new Map<string, server.task>(),
    tasks: []
  } as TasksStoreModel,
  getters: {
    getTasks: (state: TasksStoreModel) => (_boundaryId?: string, _userName?: string, _state?: string, _skip: number = 0, _take: number = 0) => {
      let result = state.tasks.filter(t => (!_boundaryId || (_boundaryId && t.boundaryId == _boundaryId))
        && (!_userName || (_userName && t.userName == _userName))
        && (!_state || (_state && t.status == _state)));
      if (_take > 0) return result.slice(_skip, (_skip + _take));
      return result;
    },
    getTasksBoundariesAutocomplete: (state: TasksStoreModel) => (
      userName?: string
    ): server.autocomplete[] => {
      let boundariesIds = state.tasks.filter(a => !userName || (userName && a.userName == userName)).map(x => x.boundaryId).filter((item, pos, self) => {
        return self.indexOf(item) == pos;
      })
      return boundariesStoreGetters.getBoundariesAutocomplete(null, boundariesIds)
    },
    getTasksUsersAutocomplete: (state: TasksStoreModel) => (
      boundariesId?: string[]
    ): server.autocomplete[] => {
      let users = usersStoreGetters.getUsersAutocomplete();
      let userNames = state.tasks.filter(a => !boundariesId || (boundariesId && (boundariesId.length <= 0 || boundariesId.indexOf(a.boundaryId) >= 0)))
        .map(m => m.userName)
        .filter((item, pos, self) => { return self.indexOf(item) == pos; });
      return users.filter(f => (!userNames || userNames && (userNames.length <= 0 || (userNames.length > 0 && userNames.indexOf(f.value) >= 0))));
    }
  },
  mutations: {
    setTasks(state: TasksStoreModel, data: server.task[]) {
      if (!data || data.length <= 0) return;
      data.forEach(i => { state._tasks.set(i.id, i); });
      fillArray(state.tasks, state._tasks.values());
    },
    deleteTask(state: TasksStoreModel, taskId: string) {
      state._tasks.delete(taskId);
      fillArray(state.tasks, state._tasks.values());
    }
  },
  actions: {
    setTasks(context, data: server.task[]) {
      context.commit('setTasks', data);
    },
    deleteTask(context, taskId) {
      context.commit("deleteTask", taskId);
    }
  }
};

export const tasksStoreActions = CreateActionsHandler<TasksStoreActions>(tasksStore);
export const tasksStoreGetters = CreateGettersHandler<TasksStoreGetters>(tasksStore);

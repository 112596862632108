import { baseRestService } from "./_base/baseRestService";
import { Config } from "@/config";

class StatService extends baseRestService {

  constructor() {
    super();
    this.baseUrl = Config.appServerUrl;
  }

  public async LayerStatus(surveyid: string, layerId: string, boundaryId: string, userName: string): Promise<server.layerStatusStat> {
    const result = await this.Get<server.layerStatusStat>(`/api/survey/${surveyid}/layers/${layerId}/stats`, {
      boundaryId,
      userName,
    });
    return result;
  }

  public async LayerData(surveyid: string, layerId: string, layerDefinitionId: string, layerGroupDefinitionId: string, boundaryId: string, userName: string): Promise<server.layerDataStat> {
    const result = await this.Get<server.layerDataStat>(`/api/survey/${surveyid}/layers/${layerId}/stats/${layerDefinitionId}`, {
      layerGroupDefinitionId,
      boundaryId,
      userName,
    });
    return result;
  }

  public async GetStatData(surveyId: string, statDefinitionId: string, boundaryId: string, userName: string): Promise<server.stat> {
    const result = await this.Get<server.stat>(`/api/survey/${surveyId}/stats/${statDefinitionId}`, {
      boundaryId,
      userName,
    });
    return result;
  }

  public async GetStatDefinitions(surveyId: string): Promise<server.statDefinition[]> {
    return await this.Get<server.statDefinition[]>(`/api/survey/${surveyId}/stats/definitions`);
  }

}

export const statService = new StatService();

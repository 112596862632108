
import { baseRestService } from "./_base";
import { boundariesStoreActions, createSurveyStoreActions } from "@/store";
import { Config } from "@/config";

class BoundariesService extends baseRestService {

  constructor() {
    super();
    this.baseUrl = Config.appServerUrl;
  }

  public async GetBoundaries(surveyid: string, code?: string, parentId?: string, withGeometries?: boolean, skip: number = 0, take: number = 100): Promise<server.boundary[]> {
    let result = await this.Get<server.boundary[]>(`/api/survey/${surveyid}/boundaries`, { code, parentId, withGeometries, skip, take });
    boundariesStoreActions.setBoundaries(result);
    return result;
  }

  public async GetEnumerationArea(surveyid: string, code?: string, parentId?: string, withGeometries?: boolean, username?: string, skip: number = 0, take: number = 100, onlyLeaf: boolean = false): Promise<server.boundary[]> {
    let result = await this.Get<server.boundary[]>(`/api/survey/${surveyid}/boundaries/enumeration-area`, { code, parentId, withGeometries, username, skip, take, onlyLeaf });
    boundariesStoreActions.setBoundaries(result);
    return result;
  }

  public async GetEnumerationAreaToClone(oldSurveyid: string, newSurveyId?: string): Promise<server.boundary[]> {
    let result = await this.Get<server.boundary[]>(`/api/survey/${oldSurveyid}/boundaries/${newSurveyId}/enumeration-area`);
    createSurveyStoreActions.setBoundaries(result);
    return result;
  }

  public async GetEnumerationAreaAutocomplete(surveyid: string, username?: string, skip: number = 0, take: number = -1, onlyLeaf: boolean = false): Promise<server.boundary[]> {
    let result = await this.Get<server.boundary[]>(`/api/survey/${surveyid}/boundaries/enumeration-area`, { withGeometries: false, username, skip, take, onlyLeaf });
    boundariesStoreActions.setBoundariesAutocomplete(result);
    return result;
  }

  public async GetBoundaryTree(surveyid: string, boundaryId: string, level?: number, username?: string): Promise<server.boundary[]> {
    let result = await this.Get<server.boundary[]>(`/api/survey/${surveyid}/boundaries/{${boundaryId}}/tree`, { level, username });
    boundariesStoreActions.setBoundaryTree(boundaryId, result);
    return result;
  }


  public async GetBoundary(surveyid: string, id?: string): Promise<server.boundary> {
    let result = await this.Get<server.boundary>(`/api/survey/${surveyid}/boundaries/${id}`);
    boundariesStoreActions.setBoundaries([result]);
    return result;
  }

  public async CreateBoundary(surveyid: string, data: server.boundary): Promise<server.boundary> {
    let result = await this.Post<server.boundary>(`/api/survey/${surveyid}/boundaries/`, { data });
    boundariesStoreActions.setBoundaries([result]);
    return result;
  }

  public async UpdateBoundary(surveyid: string, id?: string, data?: JSON): Promise<server.boundary> {
    let result = await this.Put<server.boundary>(`/api/survey/${surveyid}/boundaries/${id}`, data);
    boundariesStoreActions.setBoundaries([result]);
    return result;
  }

  public async GetShape(surveyid: string, id?: string): Promise<any> {
    return await this.downloadGet(`/api/survey/${surveyid}/shapefile/boundary/${id}`);
  }
}


export const boundariesService = new BoundariesService();

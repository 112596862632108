import { CreateActionsHandler, CreateGettersHandler, fillArray } from "./UTILS";
import { stat } from "fs";
import { Esri } from "@/esriMap";

export interface CreateSurveyStoreModel {
  survey: server.survey;
  boundaries: server.boundary[];
  layers: server.layerFull[];
  layerDatas: Map<string, server.layerData[]>;
  layerDatasFile: Map<string, File>;
  validationRules: server.layerDefinitionValidationRule[];
  statsDefinitions: server.statDefinition[];
  qualityDefinitions: server.qualityDefinition[];
  isValid: boolean;
  isEdit: boolean;
  isFooterVisible: boolean;
}

interface CreateSurveyStoreGetters {
  getSurvey: () => server.survey;
  getBoundaries: (surveyId: string) => server.boundary[];
  getLayers: (surveyId: string) => server.layerFull[];
  getLayer: (id: string) => server.layerFull;
  getLayerDatas: (layerId: string) => server.layerData[];
  getLayerDatasFile: (layerId: string) => File;
  getValidationRules: (definition: server.layerDefinitionFull) => server.layerDefinitionValidationRule[];
  getStatsDefinitions: (surveyId: string, layerId?: string) => server.statDefinition[];
  getQualityDefinitions: (surveyId: string) => server.qualityDefinition[];
  getValid: () => boolean;
  getEdit: () => boolean;
  getFooterVisible: () => boolean;
  findDataLayer: (code: string) => { layerId: string, data: server.layerData };
}

interface CreateSurveyStoreActions {
  setSurveyCentroid(center: __esri.Point);
  removeLayer(layer: server.layerFull);
  setSurvey(survey: server.survey): void;
  setBoundaries(boundaries: server.boundary[]): void;
  setLayers(layers: server.layerFull[]): void;
  setLayer(layer: server.layerFull): void;
  setLayerDatas(data: { layerId: string, layerDatas: server.layerData[] }): void;
  setLayerDatasFile(data: { layerId: string, file: File }): void;
  setValidationRule(rule: server.layerDefinitionValidationRule): void;
  removeValidationRule(rule: server.layerDefinitionValidationRule): void;
  setStatsDefinition(stat: server.statDefinition): void;
  removeStatsDefinitions(stat: server.statDefinition): void;
  setQualityDefinition(def: server.qualityDefinition): void;
  removeQualityDefinitions(def: server.qualityDefinition): void;
  setValid(valid: boolean): void;
  setEdit(edit: boolean): void;
  setFooterVisible(visible: boolean): void;
  clearAll(): void;
}

export const createSurveyStore = {
  PREFIX: "createSurvey",
  namespaced: true,
  state: {
    survey: null,
    boundaries: [],
    layers: [],
    validationRules: [],
    statsDefinitions: [],
    qualityDefinitions: [],
    layerDatas: new Map<string, server.layerData[]>(),
    layerDatasFile: new Map<string, File>(),
    isValid: false,
    isFooterVisible: true,
    isEdit: false,
  },
  getters: {
    getSurvey: (state: CreateSurveyStoreModel) => (): server.survey => {
      return state.survey;
    },
    getBoundaries: (state: CreateSurveyStoreModel) => (surveyId: string): server.boundary[] => {
      return state.boundaries.filter((f) => f.surveyId === surveyId);
    },
    getLayers: (state: CreateSurveyStoreModel) => (surveyId: string): server.layerFull[] => {
      return state.layers.filter((f) => f.surveyId === surveyId).sort((a, b) => {
        return a.orderIndex - b.orderIndex;
      });
    },
    getLayer: (state: CreateSurveyStoreModel) => (id: string): server.layerFull => {
      return state.layers.find((x) => x.id === id);
    },
    getLayerDatas: (state: CreateSurveyStoreModel) => (layerId: string): server.layerData[] => {
      return state.layerDatas.get(layerId);
    },
    getLayerDatasFile: (state: CreateSurveyStoreModel) => (layerId: string): File => {
      return state.layerDatasFile.get(layerId);
    },
    getValidationRules: (state: CreateSurveyStoreModel) => (definition: server.layerDefinition): server.layerDefinitionValidationRule[] => {
      return state.validationRules.filter((f) => f.surveyId === definition.surveyId && f.layerId === definition.layerId && f.layerDefinitionId === definition.id);
    },
    getStatsDefinitions: (state: CreateSurveyStoreModel) => (surveyId: string, layerId?: string): server.statDefinition[] => {
      return state.statsDefinitions.filter((f) => f.surveyId === surveyId && (!layerId || f.layerId === layerId));
    },
    getQualityDefinitions: (state: CreateSurveyStoreModel) => (surveyId: string): server.qualityDefinition[] => {
      return state.qualityDefinitions.filter((f) => f.surveyId === surveyId);
    },
    getValid: (state: CreateSurveyStoreModel) => (): boolean => {
      return state.isValid;
    },
    getEdit: (state: CreateSurveyStoreModel) => (): boolean => {
      return state.isEdit;
    },
    getFooterVisible: (state: CreateSurveyStoreModel) => (): boolean => {
      return state.isFooterVisible;
    },
    findDataLayer: (state: CreateSurveyStoreModel) => (code: string): { layerId: string, data: server.layerData } => {
      let layerId: string = null;
      let data: server.layerData = null;

      for (const key of Array.from(state.layerDatas.keys())) {
        const val = state.layerDatas.get(key);
        const parent = val.find((d) => d.code === code);
        if (parent) {
          layerId = key;
          data = parent;
          break;
        }
      }
      if (!layerId) {
        return null;
      }
      return { layerId, data };
    },
  },
  mutations: {
    SET_SURVEY(state: CreateSurveyStoreModel, survey: server.survey) {
      state.survey = survey;
    },
    SET_SURVEY_CENTROID(state: CreateSurveyStoreModel, center: __esri.Point) {
      state.survey.latitude = center.latitude;
      state.survey.longitude = center.longitude;
    },
    SET_BOUNDARIES(state: CreateSurveyStoreModel, boundaries: server.boundary[]) {
      state.boundaries = boundaries;
    },
    SET_LAYERS(state: CreateSurveyStoreModel, layers: server.layerFull[]) {
      state.layers = layers;
    },
    SET_LAYERDATAS(state: CreateSurveyStoreModel, data: { layerId: string, layerDatas: server.layerData[] }) {
      state.layerDatas.set(data.layerId, data.layerDatas);
    },
    SET_LAYERDATASFILE(state: CreateSurveyStoreModel, data: { layerId: string, file: File }) {
      state.layerDatasFile.set(data.layerId, data.file);
    },
    SET_LAYER(state: CreateSurveyStoreModel, layer: server.layerFull) {
      const index = state.layers.map((m) => m.id).indexOf(layer.id);
      if (index === -1) {
        state.layers.push(layer);
      } else {
        state.layers.splice(index, 1, layer);
      }
    },
    REMOVE_LAYER(state: CreateSurveyStoreModel, layer: server.layerFull) {
      const idx = state.layers.indexOf(layer);
      if (idx >= 0) {
        state.layers.splice(idx, 1);
        if(state.layerDatas.has(layer.id)){
          state.layerDatas.delete(layer.id);
        }
        if(state.layerDatasFile.has(layer.id)){
          state.layerDatasFile.delete(layer.id);
        }
      }
    },
    SET_VALIDATION_RULE(state: CreateSurveyStoreModel, rule: server.layerDefinitionValidationRule) {
      const index = state.validationRules.indexOf(rule);
      if (index === -1) {
        state.validationRules.push(rule);
      } else {
        state.validationRules.splice(index, 1, rule);
      }
    },
    REMOVE_VALIDATION_RULE(state: CreateSurveyStoreModel, rule: server.layerDefinitionValidationRule) {
      const idx = state.validationRules.indexOf(rule);
      if (idx >= 0) {
        state.validationRules.splice(idx, 1);
      }
    },
    SET_STAT_DEFINITION(state: CreateSurveyStoreModel, statDefinition: server.statDefinition) {
      const index = state.statsDefinitions.indexOf(statDefinition);
      if (index === -1) {
        state.statsDefinitions.push(statDefinition);
      } else {
        state.statsDefinitions.splice(index, 1, statDefinition);
      }
    },
    REMOVE_STAT_DEFINITION(state: CreateSurveyStoreModel, statDefinition: server.statDefinition) {
      const idx = state.statsDefinitions.indexOf(statDefinition);
      if (idx >= 0) {
        state.statsDefinitions.splice(idx, 1);
      }
    },
    SET_QUALITY_DEFINITION(state: CreateSurveyStoreModel, definition: server.qualityDefinition) {
      const index = state.qualityDefinitions.indexOf(definition);
      if (index === -1) {
        state.qualityDefinitions.push(definition);
      } else {
        state.qualityDefinitions.splice(index, 1, definition);
      }
    },
    REMOVE_QUALITY_DEFINITION(state: CreateSurveyStoreModel, definition: server.qualityDefinition) {
      const idx = state.qualityDefinitions.indexOf(definition);
      if (idx >= 0) {
        state.qualityDefinitions.splice(idx, 1);
      }
    },
    SET_STEP_VALID(state: CreateSurveyStoreModel, valid: boolean) {
      state.isValid = valid;
    },
    SET_EDIT(state: CreateSurveyStoreModel, edit: boolean) {
      state.isEdit = edit;
    },
    SET_FOOTER_VISIBLE(state: CreateSurveyStoreModel, visible: boolean) {
      state.isFooterVisible = visible;
    },
    CLEAR_ALL(state: CreateSurveyStoreModel) {
      state.boundaries.splice(0, state.boundaries.length);
      state.isFooterVisible = true;
      state.isValid = false;
      state.isEdit = false;
      state.layerDatas.clear();
      state.layers.splice(0, state.layers.length);
      state.statsDefinitions.splice(0, state.statsDefinitions.length);
      state.qualityDefinitions.splice(0, state.qualityDefinitions.length);
      state.survey = null;
      state.validationRules.splice(0, state.validationRules.length);
    },
  },
  actions: {
    setSurvey(context, survey: server.survey) {
      context.commit("SET_SURVEY", survey);
    },
    setSurveyCentroid(context, center: __esri.Point) {
      context.commit("SET_SURVEY_CENTROID", center);
    },
    setBoundaries(context, boundaries: server.boundary[]) {
      context.commit("SET_BOUNDARIES", boundaries);
    },
    setLayers(context, layers: server.layerFull[]) {
      context.commit("SET_LAYERS", layers);
    },
    setLayerDatas(context, data: { layer: server.layerFull, layerDatas: server.layerData[] }) {
      context.commit("SET_LAYERDATAS", data);
    },
    setLayerDatasFile(context, data: { layer: server.layerFull, file: File }) {
      context.commit("SET_LAYERDATASFILE", data);
    },
    setLayer(context, layer: server.layerFull) {
      context.commit("SET_LAYER", layer);
    },
    removeLayer(context, layer: server.layerFull) {
      context.commit("REMOVE_LAYER", layer);
    },
    setValidationRule(context, rule: server.layerDefinitionValidationRule) {
      context.commit("SET_VALIDATION_RULE", rule);
    },
    removeValidationRule(context, rule: server.layerDefinitionValidationRule) {
      context.commit("REMOVE_VALIDATION_RULE", rule);
    },
    setStatsDefinition(context, statDefinition: server.statDefinition) {
      context.commit("SET_STAT_DEFINITION", statDefinition);
    },
    removeStatsDefinitions(context, definition: server.statDefinition) {
      context.commit("REMOVE_STAT_DEFINITION", definition);
    },
    setQualityDefinition(context, definition: server.qualityDefinition) {
      context.commit("SET_QUALITY_DEFINITION", definition);
    },
    removeQualityDefinitions(context, definition: server.qualityDefinition) {
      context.commit("REMOVE_QUALITY_DEFINITION", definition);
    },
    setValid(context, valid: boolean) {
      context.commit("SET_STEP_VALID", valid);
    },
    setEdit(context, edit: boolean) {
      context.commit("SET_EDIT", edit);
    },
    setFooterVisible(context, visible: boolean) {
      context.commit("SET_FOOTER_VISIBLE", visible);
    },
    clearAll(context) {
      context.commit("CLEAR_ALL");
    },
  },
};

export const createSurveyStoreActions = CreateActionsHandler<CreateSurveyStoreActions>(createSurveyStore);
export const createSurveyStoreGetters = CreateGettersHandler<CreateSurveyStoreGetters>(createSurveyStore);

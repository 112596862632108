import { CreateActionsHandler, CreateGettersHandler } from "./UTILS";

interface UiStoreModel {
  position: [];
  sidebarActive: boolean;
}

interface UiStoreGetters {
  getPosition: () => [];
  getSidebarActive: () => boolean;
}

interface UiStoreActions {
  setPosition(pos);
  setSidebarActive(active)
}

export const uiStore = {
  PREFIX: "ui",
  namespaced: true,
  state: {
    position: [12.833333, 42.833333],
    sidebarActive: false,
  } ,
  getters: {
    getSidebarActive: (state: UiStoreModel) => () => {
      return state.sidebarActive;
    }
  },
  mutations: {
    SET_POSITION(state, pos) {
      state.position = pos;
    },
    SET_SIDEBAR_ACTIVE(state, active) {
      state.sidebarActive = active;
    },
  },
  actions: {
    setPosition(context, pos) {
      context.commit("SET_POSITION", pos);
    },
    setSidebarActive(context, active) {
      context.commit("SET_SIDEBAR_ACTIVE", active);
    },
  },
};

export const uiStoreActions = CreateActionsHandler<UiStoreActions>(uiStore);
export const uiStoreGetters = CreateGettersHandler<UiStoreGetters>(uiStore);

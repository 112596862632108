import Vue from "vue";
import Vuex from "vuex";

import { localizationStore, LocalizationStoreModel } from "@/store/localization.store";
import { boundariesStore, BoundariesStoreModel } from "./boundaries.store";
import { uiStore } from "./ui.store";
import { surveysStore, SurveysStoreModel } from "./surveys.store";
import { tasksStore, TasksStoreModel } from "./tasks.store";
import { layersStore, LayersStoreModel } from "./layers.store";
import { layerDatasStore, LayerDatasStoreModel } from "./layerdata.store";
import { usersStore, UsersStoreModel } from "./users.store";
import { IssuesStoreModel, issuesStore } from "./issues.store";
import { MediasStoreModel, mediasStore } from "./medias.store";
import { TrackingsStoreModel, trackingStore } from "./tracking.store";
import { createSurveyStore, CreateSurveyStoreModel } from "./createsurvey.store";
import { QualityStoreModel, qualityStore } from "./quality.store";

Vue.use(Vuex);

export interface AppModel {
  surveys: SurveysStoreModel;
  tasks: TasksStoreModel;
  layers: LayersStoreModel;
  layerDatas: LayerDatasStoreModel;
  localization: LocalizationStoreModel;
  boundaries: BoundariesStoreModel;
  users: UsersStoreModel;
  issues: IssuesStoreModel;
  medias: MediasStoreModel;
  tracking: TrackingsStoreModel;
  createSurvey: CreateSurveyStoreModel;
  quality: QualityStoreModel;
}

const appStore = {
  modules: {
    [uiStore.PREFIX]: uiStore,
    [localizationStore.PREFIX]: localizationStore,
    [boundariesStore.PREFIX]: boundariesStore,
    [surveysStore.PREFIX]: surveysStore,
    [tasksStore.PREFIX]: tasksStore,
    [layersStore.PREFIX]: layersStore,
    [layerDatasStore.PREFIX]: layerDatasStore,
    [usersStore.PREFIX]: usersStore,
    [issuesStore.PREFIX]: issuesStore,
    [mediasStore.PREFIX]: mediasStore,
    [trackingStore.PREFIX]: trackingStore,
    [createSurveyStore.PREFIX]: createSurveyStore,
    [qualityStore.PREFIX]: qualityStore,
  },
};

export const store = new Vuex.Store(appStore as any);

export const getStoreState = (): AppModel => {
  return store.state as AppModel;
};

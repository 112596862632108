import { CreateActionsHandler, CreateGettersHandler, fillArray, join } from "./UTILS";
import { getStoreState } from ".";
import { userTeamIntersection } from "./intersections/userTeam.intersection";

export interface UsersStoreModel {
  _users: Map<string, server.userTeam>;
  users: server.userTeam[];
  currentUser: server.userTeam;
}

interface UsersStoreGetters {
  getUsers(): server.userTeam[];
  currentUser(): server.userTeam;
  getUser(userId: string): IUserTeam;
  getUsersAutocomplete(usersId?: string[], showall?: boolean): server.autocomplete[];
}
interface UsersStoreActions {
  setUsers(data: server.userTeam[]);
  setUser(data: server.userTeam);
  setCurrentUser(data: server.userTeam);
  removeUser(id: string);
  clean();
}

export const usersStore = {
  PREFIX: "users",
  namespaced: true,
  state: {
    _users: new Map<string, server.userTeam>(),
    users: [],
    currentUser: null,
  } as UsersStoreModel,
  getters: {
    getUsers: (state: UsersStoreModel) => () => {
      return state.users;
    },
    currentUser: (state: UsersStoreModel) => () => {
      return state.currentUser;
    },
    getUser: (state: UsersStoreModel) => (userId: string): IUserTeam => {
      const user = state.users.find((x) => x.id === userId || x.userName === userId);
      if (user) {
        const extended = join(user)(userTeamIntersection);
        return extended;
      } else {
        return null;
      }
    },
    getUsersAutocomplete: (state: UsersStoreModel) => (usersId?: string[], showall: boolean = true): server.autocomplete[] => {
      const currentUser = usersStoreGetters.currentUser();
      return state.users.filter((u) => (!usersId || (usersId && usersId.indexOf(u.id) >= 0))
        && (showall || (!u.isAdmin && !u.isOwner && (!currentUser.isAdmin && !currentUser.isOwner ? u.isContributor === false : true))))
        .map((m) => {
          return {
            key: m.id,
            value: m.userName,
            description: m.description,
          } as server.autocomplete;
        },
        );
    },
  },
  mutations: {
    setUsers(state: UsersStoreModel, data: server.userTeam[]) {
      data.forEach((i) => { state._users.set(i.id, i); }); // freeze
      fillArray(state.users, state._users.values());
    },
    setUser(state: UsersStoreModel, data: server.userTeam) {
      state._users.set(data.id, data);
      fillArray(state.users, state._users.values());
    },
    setCurrentUser(state: UsersStoreModel, data: server.userTeam) {
      state.currentUser = data;
    },
    removeUser(state: UsersStoreModel, id: string) {
      state._users.delete(id);
      fillArray(state.users, state._users.values());
    },
    clean(state: UsersStoreModel) {
      state._users.clear();
      state.users.splice(0, state.users.length); // clean
    },
  },
  actions: {
    setUsers(context, data: server.userTeam[]) {
      context.commit("setUsers", data);
    },
    setUser(context, data: server.userTeam) {
      context.commit("setUser", data);
    },
    setCurrentUser(context, data: server.userTeam) {
      context.commit("setCurrentUser", data);
    },
    removeUser(context, id: string) {
      context.commit("removeUser", id);
    },
  },
};

export const usersStoreActions = CreateActionsHandler<UsersStoreActions>(
  usersStore,
);
export const usersStoreGetters = CreateGettersHandler<UsersStoreGetters>(
  usersStore,
);

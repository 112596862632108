const UsersIndex = () =>
    import ( /* webpackChunkName: "users" */ "./index.vue");

export const routes = [{
    path: "/team",
    name: "team",
    component: UsersIndex,
    meta: {
        basename: "team",
        roles: {
            isAdmin: true
        }
    }
}];
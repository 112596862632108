import { CreateActionsHandler, CreateGettersHandler, fillArray } from "./UTILS";

export interface MediasStoreModel {
  _mediaIds: Map<string, server.mediaIds>;
  mediaIds: server.mediaIds[];
  _medias: Map<string, server.media>;
  medias: server.media[];
}

interface MediasStoreGetters {
  getMedia(mediaId: string): string;
  getMedias(typeName: string, typeId: string): server.media[];
  getMediaIds(typeName: string, typeId: string): string[];
}

interface MediasStoreActions {
  setMediaIds(data: server.mediaIds);
  deleteMediaIds(data: server.mediaIds);
  setMedia(data: server.media);
  deleteMedia(data: server.media);
}

export const mediasStore = {
    PREFIX: "medias",
    namespaced: true,
    state: {
      _mediaIds: new Map<string, server.mediaIds>(),
      mediaIds: [],
      _medias: new Map<string, server.media>(),
      medias: [],
    } as MediasStoreModel,
    getters: {
      getMedia: (state: MediasStoreModel) => (mediaId: string): string => {
        return (state.medias.find((x) => x.id === mediaId) || {} as server.media).data;
      },
      getMedias: (state: MediasStoreModel) => (typeName: string, typeId: string): server.media[] => {
        return (state.medias.filter((x) => x.type === typeName && x.typeId === typeId) || [] as server.media[]);
      },
      getMediaIds: (state: MediasStoreModel) => (typeName: string, typeId: string): string[] => {
        return (state.mediaIds.find((x) => x.typeName === typeName && x.typeId === typeId) || {} as server.mediaIds).ids || [];
      },
    },
    mutations: {
      setMediaIds(state: MediasStoreModel, data: server.mediaIds) {
        if (!data) { return; }
        state._mediaIds.set(`${data.typeName}_${data.typeId}`, data);
        fillArray(state.mediaIds, state._mediaIds.values());
      },
      deleteMediaIds(state: MediasStoreModel, data: server.mediaIds) {
        state._mediaIds.delete(`${data.typeName}_${data.typeId}`);
        fillArray(state.mediaIds, state._mediaIds.values());
      },
      setMedia(state: MediasStoreModel, data: server.media) {
        if (!data) { return; }
        state._medias.set(data.id, data);
        fillArray(state.medias, state._medias.values());
      },
      deleteMedia(state: MediasStoreModel, data: server.media) {
        state._medias.delete(data.id);
        fillArray(state.medias, state._medias.values());
      },
    },
    actions: {
      setMediaIds(context, data: server.mediaIds) {
        context.commit("setMediaIds", data);
      },
      deleteMediaIds(context, data: server.mediaIds) {
        context.commit("deleteMediaIds", data);
      },
      setMedia(context, data: server.media) {
        context.commit("setMedia", data);
      },
      deleteMedia(context, data: server.media) {
        context.commit("deleteMedia", data);
      },
    },
  };

export const mediasStoreActions = CreateActionsHandler<MediasStoreActions>(mediasStore);
export const mediasStoreGetters = CreateGettersHandler<MediasStoreGetters>(mediasStore);

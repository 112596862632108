const classRelative = "busy-component-relative";
const classBackground = "busy-component-background";

const _toggleLoaderClass = function(el, binding) {
  let loaderClass = classRelative;
  if (binding.modifiers.background)
    loaderClass = classBackground;

  if (binding.value && !el.classList.contains(loaderClass))
    el.classList.add(loaderClass);

  if (typeof binding.value === "object" && typeof binding.value.finally == "function") {
    binding.value.catch(() => null).finally(() => {
      el.classList.remove(loaderClass);
    });
  } else {
    if (!binding.value && el.classList.contains(loaderClass))
      el.classList.remove(loaderClass);
  }
};

export default {
  inserted(el, binding) {
    _toggleLoaderClass(el, binding);
  },
  update(el, binding) {
    _toggleLoaderClass(el, binding);
  }
};

import Vue from "vue";
import moment from "moment";

Vue.filter("dateformat", function(value, format) {
  let date;
  if (!value) return "";

  if (value instanceof Date) date = moment(value);
  else date = moment.utc(String(value), moment.HTML5_FMT.DATETIME_LOCAL_MS).local();
  if (!date.isValid()) {
    if (DEVELOPMENT) console.debug("Date format is invalid", { originalValue: value, formatRequired: format, formatValue: date });
    return "";
  }

  return date.format(format);
});

import Vue from "vue";
import { store } from "@/store";
import { Config } from "@/config";

var sc = document.createElement("script");
sc.setAttribute("src", Config.appSignalrHub + "/hubs");
sc.setAttribute("type", "text/javascript");
document.head.appendChild(sc);

import { ServiceInizialization } from "@/app_startup/startup";
// modificate qui se volete attivare i servizi di sviluppo di vue
Vue.config.devtools = true;
Vue.config.performance = false;

// Mi assicuro che durante la pubblicazione i servizi di sviluppo di VUE siano disattivati.
if (!DEVELOPMENT) {
  Vue.config.devtools = false;
  Vue.config.performance = false;
}

// import vgl from 'vue-golden-layout'
// import 'golden-layout/src/css/goldenlayout-light-theme.css'
// Vue.use(vgl)

import { Loader } from "@/esriMap";
Loader.initialize();

import CensusDatePicker from "./components/datepicker/datepicker.vue";
Vue.component("date-picker", CensusDatePicker);

import * as directives from "./directives";
const VueDynamicRegister = {
  install: function(Vue) {
    // Register directives
    for (var directive in directives) {
      Vue.directive(directive, directives[directive]);
    }
  },
};
Vue.use(VueDynamicRegister);

import "./filters/localize";
import "./filters/dateFormat";
import "./filters/gravatar";
import "./filters/distance";
import "./filters/round";
import "./filters/emailFormat";

import VeeValidate, { Validator } from "vee-validate";
import messagesIt from "vee-validate/dist/locale/it";
import messagesEn from "vee-validate/dist/locale/en";
import messagesFr from "vee-validate/dist/locale/fr";
import messagesPt from "vee-validate/dist/locale/pt_PT";

Validator.localize("it", messagesIt);
Validator.localize("en", messagesEn);
Validator.localize("fr", messagesFr);
Validator.localize("pt", messagesPt);

import VueHighcharts from "vue2-highcharts";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";

export const uuidv4 = require("uuid/v4");

Vue.use(VueHighcharts);
HighchartsMore(Highcharts);

Vue.use(VeeValidate, {
  locale: "it",
  fieldsBagName: "vfields",
  validity: true,
  classes: true,
  classNames: {
    valid: "is-valid",
    invalid: "is-invalid",
  },
  dictionary: {
    en: {
      messages: messagesEn,
    },
    it: {
      messages: messagesIt,
    },
    fr: {
      messages: messagesFr,
    },
    pt: {
      messages: messagesPt,
    },
  },
});

import resize from "vue-resize-directive";
Vue.directive("resize", resize);

import isvalid from "./mixins/isValid";
Vue.mixin(isvalid);

import router from "./router";

import App from "./App.vue";
export var CensusApp = null;
ServiceInizialization().then(result => {
  setTimeout(() => {
    CensusApp = new Vue({
      el: "#app",
      router,
      store,
      template: "<app/>",
      components: {
        App,
      },
    });
  }, 1000);
});

import Vue from "vue";
import DatePicker from "vuejs-datepicker";
import * as dpLanguages from "vuejs-datepicker/dist/locale";
import moment from "moment";
import { Component, Prop } from "vue-property-decorator";

@Component({
  name: "date-picker",
  components: {
    vdatepicker: DatePicker
  }
})
export default class CensusDatepicker extends Vue {
  @Prop()
  value: any;

  @Prop()
  name: string;

  @Prop()
  id: string;

  @Prop()
  placeholder: string;

  // @Prop({ default: undefined })
  // disabledDates: any;

  @Prop({ default: "start" })
  truncateTime: string;

  languages = dpLanguages;

  get disabledDates() {
    if (this.$attrs["disabled-dates"] === undefined) return {};
    let dd = Object.assign({}, this.$attrs["disabled-dates"]) as any;
    if (this.truncateTime !== "start" && this.truncateTime !== "end") return dd;
    dd.from = dd.from
      ? moment(dd.from)
          .endOf("day")
          .toDate()
      : null;
    dd.to = dd.to
      ? moment(dd.to)
          .startOf("day")
          .toDate()
      : null;
    return dd;
  }

  get required() {
    if (this.$attrs["required"] === undefined) return false;
    return this.$attrs["required"] === "" || !!this.$attrs["required"];
  }

  get disabled() {
    if (this.$attrs["disabled"] === undefined) return false;
    return this.$attrs["disabled"] === "" || !!this.$attrs["disabled"];
  }

  get currentLanguage() {
    return this.$store.state.localization.currentLanguage;
  }

  get bindingValue() {
    return this.value;
  }
  set bindingValue(v) {
    v = moment(v);
    if (this.truncateTime == "start") {
      v = v.startOf("day");
    }
    if (this.truncateTime == "end") {
      v = v.endOf("day");
    }
    this.$emit("input", v.toDate());
  }
}

import { Esri } from "@/esriMap";
import { geojsonToArcGIS } from "@esri/arcgis-to-geojson-utils";
import { propertyResolve } from "..";
import { layersStoreGetters } from "../layers.store";
import { layersService } from "@/services";

export class LayerDataIntersection implements ILayerData {
  private static pointConstructor: __esri.PointConstructor = null;
  private static polygonConstructor: __esri.PolygonConstructor = null;

  public surveyId: any;
  public layerId: any;
  public gid: any;
  public layerName: string;
  public code: string;
  public boundaryId?: any;
  public parentId?: any;
  public geoJSON: any;
  public latitude: number;
  public longitude: number;
  public jsonData: string;
  public date: Date;
  public status: number;
  public lastUpdateDate?: Date;
  public lastUpdateUsername: string;
  public lastUpdateUserId?: string;

  public supervisorStatus: server.dataSupervisorStatusEnum;
  public supervisorIssueRef?: any;
  public supervisorMessage: string;
  public supervisorLogDatas: any[];

  get layer(): server.layerFull {
    return propertyResolve(layersStoreGetters.getLayer(this.surveyId, this.layerId), () => layersService.GetLayer(this.surveyId, this.layerId), this.layerId);
  }

  get symbol() {
    let symbol = this.layer.defaultSymbolDefinition as __esri.Symbol;
    if (this.symbolDefinitions && this.jsonData) {
      const symbolDefinition = this.symbolDefinitions.find((x) => x.value === this.jsonData[this.layer.symbolField]);
      if (symbolDefinition) {
        symbol = symbolDefinition.symbol;
      }
    }
    return symbol;
  }

  get symbolDefinitions(): Array<{ value: string, symbol: __esri.Symbol }> {
    return this.layer.symbolDefinition;
  }

  constructor() {
    this.Initialize();
  }

  public geometryESRI() {
    if (!this.layer || !this.geoJSON) {
      return null;
    }

    if (this.layer.geometryType === "point" && LayerDataIntersection.pointConstructor) {
      return new LayerDataIntersection.pointConstructor(geojsonToArcGIS(this.geoJSON)) as any;
    }

    if (this.layer.geometryType === "polygon" && LayerDataIntersection.polygonConstructor) {
      return new LayerDataIntersection.polygonConstructor(geojsonToArcGIS(this.geoJSON)) as any;
    }
    return null;

  }

  private async Initialize() {
    LayerDataIntersection.pointConstructor = await Esri.Geometry.PointConstructor();
    LayerDataIntersection.polygonConstructor = await Esri.Geometry.PolygonConstructor();
  }

}

export const layerDataIntersection = new LayerDataIntersection();

import { CreateActionsHandler, CreateGettersHandler, fillArray } from "./UTILS";
import { usersStoreGetters } from ".";
import { boundariesStoreGetters } from "./boundaries.store";

export interface IssuesStoreModel {
  _issues: Map<string, server.issue>;
  issues: server.issue[];
}

interface IssuesStoreGetters {
  getIssues(): server.issue[];
  getOpenIssues(): server.issue[];
  getUserIssues(userName: string): server.issue[];
  getBoundaryIssues(boundaryId: string): server.issue[];
  getIssuesBoundariesAutocomplete(userName: string): server.autocomplete[];
  getIssuesUsersAutocomplete(boundariesId: string[]): server.autocomplete[];
}

interface IssuesStoreActions {
  setIssues(data: server.issue[]);
  deleteIssue(issueId: string);
}

export const issuesStore = {
  PREFIX: "issues",
  namespaced: true,
  state: {
    _issues: new Map<string, server.issue>(),
    issues: [],
  } as IssuesStoreModel,
  getters: {
    getIssues: (state: IssuesStoreModel) => () => {
      return state.issues;
    },
    getOpenIssues: (state: IssuesStoreModel) => () => {
      return state.issues.filter((x) => (x.state === server.IssueStatus.open || x.state === server.IssueStatus.toBeCheckedBySupervisor))
                         .sort((a, b) => b.creationDate.getTime() - a.creationDate.getTime());
    },
    getUserIssues: (state: IssuesStoreModel) => (userName: string) => {
      return state.issues.filter((t) => t.userName === userName)
                         .sort((a, b) => b.creationDate.getTime() - a.creationDate.getTime());
    },
    getBoundaryIssues: (state: IssuesStoreModel) => (boundaryId: string) => {
      return state.issues.filter((t) => t.boundaryId === boundaryId)
                         .sort((a, b) => b.creationDate.getTime() - a.creationDate.getTime());
    },
    getIssuesBoundariesAutocomplete: (state: IssuesStoreModel) => (userName?: string): server.autocomplete[] => {
      const boundariesIds = state.issues.filter((a) => !userName || (userName && a.userName === userName)).map((x) => x.boundaryId).filter((item, pos, self) => {
        return self.indexOf(item) === pos;
      });
      return boundariesStoreGetters.getBoundariesAutocomplete(null, boundariesIds);
    },
    getIssuesUsersAutocomplete: (state: IssuesStoreModel) => (boundariesId?: string[],
    ): server.autocomplete[] => {
      const users = usersStoreGetters.getUsersAutocomplete();
      const userNames = state.issues.filter((a) => !boundariesId || (boundariesId && (boundariesId.length <= 0 || boundariesId.indexOf(a.boundaryId) >= 0)))
        .map((m) => m.userName)
        .filter((item, pos, self) => self.indexOf(item) === pos);
      return users.filter((f) => (!userNames || userNames && (userNames.length <= 0 || (userNames.length > 0 && userNames.indexOf(f.value) >= 0))));
    },
  },
  mutations: {
    setIssues(state: IssuesStoreModel, data: server.issue[]) {
      if (!data || data.length <= 0) { return; }
      data.filter((x) => !!x).forEach((i) => { state._issues.set(i.id, i); });
      fillArray(state.issues, state._issues.values());
    },
    deleteIssue(state: IssuesStoreModel, issueId: string) {
      state._issues.delete(issueId);
      fillArray(state.issues, state._issues.values());
    },
  },
  actions: {
    setIssues(context, data: server.issue[]) {
      context.commit("setIssues", data);
    },
    deleteIssue(context, issueId: string) {
      context.commit("deleteIssue", issueId);
    },
  },
};

export const issuesStoreActions = CreateActionsHandler<IssuesStoreActions>(issuesStore);
export const issuesStoreGetters = CreateGettersHandler<IssuesStoreGetters>(issuesStore);


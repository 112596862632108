
import { baseRestService } from "./_base/baseRestService";
import { surveysStoreActions } from "@/store";

import { Config } from "@/config";

import { Hub, INotificationMessage } from "./hub";
import { MessageService, Factory } from "./_base";

class SurveysService extends baseRestService {

  constructor() {
    super();
    this.baseUrl = Config.appServerUrl;

    // Ricevo le notifiche degli avanzamenti dei task
    Hub.Current.subscribeToMessage((client) => {
      client.surveyStateChange = async (message: INotificationMessage) => {
        const survey = await this.GetSurveyById(message.surveyId);
        if (survey) {
          Factory.Get(MessageService).publishToChannel("update-survey", survey);
        }
      };
    });
  }

  public async GetSurveys(skip?: number, take?: number): Promise<server.survey[]> {
    const result = await this.Get<server.survey[]>(`/api/surveys`, { skip, take });
    surveysStoreActions.setSurveys(result);
    return result;
  }

  public async GetSurveyById(Id?: string): Promise<server.survey> {
    const result = await this.Get<server.survey>(`/api/survey/${Id}`);
    surveysStoreActions.setSurveys([result]);
    return result;
  }

  public async GetSurveyByIdNoStored(Id?: string): Promise<server.survey> {
    return await this.Get<server.survey>(`/api/survey/${Id}`);
  }
  

  public async PostSurveysId(modelBody?: server.survey): Promise<server.survey> {
    const result = await this.Post<server.survey>(`/api/survey/`, { modelBody });
    surveysStoreActions.setSurveys([result]);
    return result;
  }

  public async PutSurveysId(Id?: number, modelBody?: server.survey): Promise<server.survey> {
    const result = await this.Put<server.survey>(`/api/survey/${Id}`, modelBody);
    surveysStoreActions.setSurveys([result]);
    return result;
  }

  public async DeletSurveysId(id?: string): Promise<server.survey> {
    const result = await this.Delete<server.survey>(`/api/survey/${id}`);
    surveysStoreActions.deleteSurvey(id);
    return result;
  }

  public async recalcTree(id:string): Promise<void>{
    return await this.Get<void>(`/api/survey/${id}/recalctree`, null);
  }
}

export const surveysService = new SurveysService();

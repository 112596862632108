import { resolverConfiguration } from "@/store/UTILS";
import {
  usersInBoundariesService, tasksService, boundariesService,
  securityService, localizationService, surveysService, userService, issuesService,
} from "@/services";
import { AlertHelper } from "@/services/_base/AlertHelper";
import { Hub } from "@/services/hub";
import { surveysStoreGetters } from "@/store";

export async function ServiceInizialization() {
  if (await securityService.isUserAuthenticated()) {
    let username: string = null;

    AlertHelper.showLoader("loading");
    try {
      await localizationService.handshake();
      await surveysService.GetSurveys();
      username = securityService.getUsername();

    } catch (err) {
      AlertHelper.showError("user_not_authorized_title", "user_not_authorized_message", 10000, "topCenter", () => {
        securityService.Logout();
      }, () => {
        securityService.Logout();
      });
    }

    const survey = surveysStoreGetters.currentSurvey();
    if (!survey) {
      AlertHelper.showError("user_not_have_census_title", "user_not_have_census_message", 10000, "topCenter", () => {
        securityService.Logout();
      }, () => {
        securityService.Logout();
      });
    }

    await Promise.all([
      userService.GetCurrentUserSurvey(survey.id, username),
      userService.GetUsers(survey.id),
      boundariesService.GetEnumerationAreaAutocomplete(survey.id),
      usersInBoundariesService.GetUsersInBoundaries(survey.id),
      tasksService.GetTasks(survey.id),
      // issuesService.GetIssues(survey.id, null, null, null, server.IssueStatus.open),
      issuesService.GetIssues(survey.id),
    ]);

    // register for signalr notifications
    Hub.Current.initConnection();
    Hub.Current.registerForSurvey();

    AlertHelper.hideLoader();
    resolverConfiguration.delayResolver = false;
  }
}

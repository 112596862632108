import { CreateActionsHandler, CreateGettersHandler, join, fillArray } from "./UTILS";
import { layerDataIntersection } from "./intersections/layerDatas.intersection";

export interface LayerDatasStoreModel {
  _layerDatas: Map<string, ILayerData>;
  layerDatas: ILayerData[];
}

interface LayerDatasStoreGetters {
  getRelatedData(dataId: string): ILayerData[];
  getLayerDatas(boundaryId: string): ILayerData[];
  getLayerData(gid: string): ILayerData;
}
interface LayerDatasStoreActions {
  setLayerDatas(data: server.layerData[]);
  deleteLayerData(layerDataId: string);
  clean();
  deleteBoundaryLayerDatas(buoundaryData: any);
}

export const layerDatasStore = {
  PREFIX: "layerDatas",
  namespaced: true,
  state: {
    _layerDatas: new Map<string, server.layerData>(),
    layerDatas: [],
  } as LayerDatasStoreModel,
  getters: {
    getRelatedData: (state: LayerDatasStoreModel) => (dataId: string): ILayerData[] => {
      const parentId = (state._layerDatas.get(dataId) || { parentId: "" }).parentId;
      return state.layerDatas.filter((d) => d.parentId === dataId || parentId === d.gid);
    },
    getLayerDatas: (state: LayerDatasStoreModel) => (boundaryId: string): ILayerData[] => {
      return state.layerDatas.filter((d) => d.boundaryId === boundaryId);
    },
    getLayerData: (state: LayerDatasStoreModel) => (gid: string): ILayerData => {
      return state.layerDatas.find((d) => d.gid === gid);
    },
  },
  mutations: {
    setLayerDatas(state: LayerDatasStoreModel, data: server.layerData[]) {
      if (!data || data.length <= 0) {
        return;
      }
      data.forEach((i) => { state._layerDatas.set(i.gid, join(i)(layerDataIntersection)); });
      fillArray(state.layerDatas, state._layerDatas.values());
    },
    deleteLayerData(state: LayerDatasStoreModel, layerDataId: string) {
      state._layerDatas.delete(layerDataId);
      fillArray(state.layerDatas, state._layerDatas.values());
    },
    clean(state) {
      state._layerDatas = new Map<string, server.layerData>();
      fillArray(state.layerDatas, state._layerDatas.values());
    },
    deleteBoundaryLayerDatas(state: LayerDatasStoreModel, buoundaryData: any) {
      const toRemove = state.layerDatas.filter((d) => (d.surveyId === buoundaryData.surveyId && d.layerId === buoundaryData.layerId && d.boundaryId === buoundaryData.boundaryId));
      const idsRemove: number[] = [];

      state.layerDatas.forEach((l) => {
        const found = toRemove.find((t) => t.gid === l.gid);
        if (found) {
          idsRemove.push(state.layerDatas.indexOf(found));
        }
      });

      idsRemove.forEach((id) => {
        state.layerDatas.splice(id, 1);
      });
    },
  },
  actions: {
    setLayerDatas(context, data: server.layerData[]) {
      context.commit("setLayerDatas", data);
    },
    deleteLayerData(context, data: server.layerData) {
      context.commit("deleteLayerData", data);
    },
    deleteBoundaryLayerDatas(context, buoundaryData: any) {
      context.commit("deleteBoundaryLayerDatas", buoundaryData);
    },
    clean(context) {
      context.commit("clean");
    },
  },
};

export const layerDatasStoreActions = CreateActionsHandler<LayerDatasStoreActions>(layerDatasStore);
export const layerDatasStoreGetters = CreateGettersHandler<LayerDatasStoreGetters>(layerDatasStore);

import { CreateActionsHandler, CreateGettersHandler, fillArray, join } from './UTILS';
import { trackingIntersection } from './intersections/tracking.intersection';

export interface TrackingsStoreModel {
  _tracking: Map<string, ITracking>;
  tracking: ITracking[];
}

interface TrackingsStoreGetters {
  getDailyTracking(username: string, date: Date): ITracking[];
  getTracking(username?: string, fromDate?: Date, toDate?: Date): ITracking[];
}

interface TrackingsStoreActions {
  setTracking(data: server.track[]);
  clean();
}

export const trackingStore = {
  PREFIX: "tracking",
  namespaced: true,
  state: {
    _tracking: new Map<string, ITracking>(),
    tracking: []
  } as TrackingsStoreModel,
  getters: {
    getDailyTracking: (state: TrackingsStoreModel) => (username: string, date: Date) => {
      const fromDate = date;
      const toDate = new Date(new Date(fromDate).setDate(fromDate.getDate() + 1));
      return state.tracking.filter(w => (!username || w.username == username) && (!fromDate || w.date >= fromDate) && (!toDate || w.date <= toDate));
    },
    getTracking: (state: TrackingsStoreModel) => (username: string, fromDate: Date, toDate: Date) => {
      return state.tracking.filter(w => (!username || w.username == username) && (!fromDate || w.date >= fromDate) && (!toDate || w.date <= toDate));
    }
  },
  mutations: {
    setTracking(state: TrackingsStoreModel, data: server.track[]) {
      if (!data || data.length <= 0) return;
      state._tracking = new Map<string, ITracking>(); //pulisco le posizioni precedenti
      data.forEach(i => { state._tracking.set(`${i.username}_${i.date.getTime()}`, join(i)(trackingIntersection)); });
      fillArray(state.tracking, state._tracking.values());
    },
    clean(state: TrackingsStoreModel) {
      state._tracking = new Map<string, ITracking>(); //pulisco le posizioni precedenti
      fillArray(state.tracking, state._tracking.values());
    }
  },
  actions: {
    setTracking(context, data: server.track[]) {
      context.commit('setTracking', data);
    },
    clean(context, data: server.track[]) {
      context.commit('clean', data);
    }
  }
};

export const trackingStoreActions = CreateActionsHandler<TrackingsStoreActions>(trackingStore);
export const trackingStoreGetters = CreateGettersHandler<TrackingsStoreGetters>(trackingStore);

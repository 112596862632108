export class Deferred<T> {
  public resolve: (value?: T | Promise<T>) => void;
  public reject: (reason?: any) => void;
  public promise: Promise<T>;
  constructor() {
    this.promise = new Promise<T>((res, rej) => {
      this.resolve = res;
      this.reject = rej;
    });
  }
}

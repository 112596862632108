const LogsIndex = () =>
    import ( /* webpackChunkName: "logs" */ "@/app_modules/logs/index.vue");

export const routes = [{
    path: "logs/",
    component: {
        template: "<router-view></router-view>",
    },
    meta: {
        basename: "logs:main",
        featureFlag: "logs",
        roles: {
            basename: 'logs',
            isAdmin: true,
        },
    },
    children: [{
        path: "events",
        name: "logs",
        component: LogsIndex,
        props: false,
        meta: {
            basename: 'logs',
            featureFlag: "logs",
            roles: {
                isAdmin: true,
            },
        },
    }, ],
}, ];
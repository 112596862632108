import Vue from "vue";
import { FactoryService as Factory } from "@/services/_base/factoryService";
// --------------------------------------------------
// Message Service Definition
// --------------------------------------------------

export class MessageService {

  private _events: Vue = new Vue({});

  subscribeToChannel(name: string, callback: Function, once: boolean = false) {
    if (once)
      this._events.$once(name, callback);
    else
      this._events.$on(name, callback);
  }

  deleteChannel(name: string) {
    this._events.$off(name);
  }

  unsubscribeFromChannel(name: string, callback: Function) {
    this._events.$off(name, callback);
  }

  publishToChannel<T>(name: string, ...args: any[]) {
    this._events.$emit(name, args);
  }
}

export const messageService = new MessageService();
Factory.SetByName("MessageService", messageService);





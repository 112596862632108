import { baseRestService } from "./_base/baseRestService";
import { Config } from "@/config";
import { layerDatasStoreActions } from "@/store/layerdata.store";

class LayerDatasService extends baseRestService
{

  constructor()
  {
    super();
    this.baseUrl = Config.appServerUrl;
  }

  public async CountLayerDatas(surveyid: string, layerId: string, boundaryId?: string, status?: number, supervisorStatus?: string, username?: string, showHistory?: boolean): Promise<number>
  {
    return await this.Get<number>(`/api/survey/${surveyid}/layers/${layerId}/datas/count`, { boundaryId, showHistory, supervisorStatus, status, username });
  }

  public async GetLayerDatas(surveyid: string, layerId: string, boundaryId?: string, status?: number, supervisorStatus?: string, username?: string, showHistory?: boolean, skip?: number, take?: number): Promise<server.layerData[]>
  {
    const result = await this.Get<server.layerData[]>(`/api/survey/${surveyid}/layers/${layerId}/datas`, { boundaryId, skip, take, showHistory, supervisorStatus, status, username });
    layerDatasStoreActions.setLayerDatas(result);
    return result;
  }

  public async GetLayerData(surveyid: string, layerId: string, gid: string): Promise<server.layerData>
  {
    const result = await this.Get<server.layerData>(`/api/survey/${surveyid}/layers/${layerId}/datas/${gid}`);
    layerDatasStoreActions.setLayerDatas([result]);
    return result;
  }

  public async GetLayerDataIdsByExtent(surveyid: string, layerId: string, extent: __esri.Extent, skip?: number, take?: number): Promise<server.layerData[]>
  {
    const result = await this.Get<server.layerData[]>(`/api/survey/${surveyid}/layers/${layerId}/datas/ids`,
      { xmin: extent.xmin, xmax: extent.xmax, ymin: extent.ymin, ymax: extent.ymax, skip, take });
    return result;
  }

  public async GetLayerDataByIds(surveyid: string, layerId: string, ids: string[], skip?: number, take?: number): Promise<server.layerData[]>
  {
    const result = await this.Post<server.layerData[]>(`/api/survey/${surveyid}/layers/${layerId}/datas/ids`,
      { surveyId: surveyid, layerId, ids, take, skip });
    layerDatasStoreActions.setLayerDatas(result);
    return result;
  }

  public async SetLayerData(surveyid: string, layerId: string, data: any): Promise<server.layerData>
  {
    const result = await this.Post<server.layerData>(`/api/survey/${surveyid}/layers/${layerId}/datas/`, data);
    layerDatasStoreActions.setLayerDatas([result]);
    return result;
  }

  public async editLayerData(surveyid: string, layerId: string, gid: string, data: any): Promise<server.layerData>
  {
    const result = await this.Put<server.layerData>(`/api/survey/${surveyid}/layers/${layerId}/datas/${gid}`, data);
    layerDatasStoreActions.setLayerDatas([result]);
    return result;
  }

  public async editGeometry(surveyid: string, layerDataId: string, gid: string, data: any)
  {
    const result = await this.Put<server.layerData>(`/api/survey/${surveyid}/layers/${layerDataId}/datas/${gid}/geometry`, data);
    result.status = result.status | 2;
    layerDatasStoreActions.setLayerDatas([result]);
  }

  public async setSupervisorMessage(surveyid: string, layerDataId: string, gid: string, data: server.layerdataSupervisor)
  {
    const result = await this.Put<server.layerData>(`/api/survey/${surveyid}/layers/${layerDataId}/datas/${gid}/supervisor`, data);
    layerDatasStoreActions.setLayerDatas([result]);
    return result;
  }

  public async deleteItem(surveyid: string, layerDataId: string, gid: string)
  {
    const result = await this.Delete<server.layerData>(`/api/survey/${surveyid}/layers/${layerDataId}/datas/${gid}`);
    layerDatasStoreActions.deleteLayerData(gid);
    return result;
  }

  public async setCompletedLayerData(surveyid: string, layerDataId: string, gid: string)
  {
    const result = await this.Put<server.layerData>(`/api/survey/${surveyid}/layers/${layerDataId}/datas/${gid}/status?completed=true&problems=false`, null);
    result.status = result.status | 2;
    layerDatasStoreActions.setLayerDatas([result]);
  }

  public async setProblemLayerData(surveyid: string, layerDataId: string, gid: string)
  {
    const result = await this.Put<server.layerData>(`/api/survey/${surveyid}/layers/${layerDataId}/datas/${gid}/status?completed=false&problems=true`, null);
    layerDatasStoreActions.setLayerDatas([result]);
    return result;
  }
}

export const layerDatasService = new LayerDatasService();

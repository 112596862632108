import { baseRestService } from "./_base/baseRestService";
import { Config } from "@/config";
import { mediasStoreActions } from "@/store/medias.store";

class MediasService extends baseRestService {
  private _organizationsUrl: string;
  constructor() {
    super();
    this.baseUrl = Config.appServerUrl;
  }

  async ids(typeName: string, typeId: string) : Promise<string[]> {
    let result = await this.Get<string[]>("/api/images/"+typeName+"/"+typeId+"/ids");
    mediasStoreActions.setMediaIds({typeName: typeName, typeId: typeId, ids: result} as server.mediaIds);
    return result;
  }

  async getMedia(mediaId:string) : Promise<string> {
    let result = await this.Get<server.media>("/api/images/"+mediaId);
    mediasStoreActions.setMedia(result);
    return (result || {} as server.media).data;
  }

  resize(filename: string, width: number, height: number): string {
    if(!filename) return "";
    return `${Config.appServerUrl}/api/images/resize/${filename}?width=${width}&height=${height}&crop=true`;
  }
}

export const mediasService = new MediasService();
import { baseRestService } from "./_base/baseRestService";
import { Config } from "@/config";
import { qualityStoreActions } from "@/store/quality.store";

class QualityService extends baseRestService {

    constructor() {
        super();
        this.baseUrl = Config.appServerUrl;
    }

    public async GetQualityData(surveyId: string, boundaryId: string, username: string) {
        const res = await this.Get<any[]>(`/api/survey/${surveyId}/quality`, {
            boundaryId,
            username,
        });

        return res;
    }

    public async CountQualityData(surveyId: any, boundaryId: any, username: string): Promise<number> {
        const res = await this.Get<number>(`/api/survey/${surveyId}/quality/counter`, {
            boundaryId,
            username,
        });

        return res;
    }

    public async GetQualityDefiniions(surveyId: any): Promise<server.qualityDefinition[]> {
        const res =  await this.Get<server.qualityDefinition[]>(`/api/survey/${surveyId}/quality/definitions`);
        qualityStoreActions.setQualityDefinitons(res);
        return res;
    }

    public async GetQualityDefiniionsNotStored(surveyId: any): Promise<server.qualityDefinition[]> {
        return  await this.Get<server.qualityDefinition[]>(`/api/survey/${surveyId}/quality/definitions`);
    }
}

export const qualityService = new QualityService();

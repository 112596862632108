const trackingIndex = () =>
    import ( /* webpackChunkName: "tracking" */ "@/app_modules/tracking/index.vue");

export const routes = [{
    path: "/tracking",
    component: {
        template: '<router-view></router-view>'
    },
    meta: {
        basename: "tracking",
        featureFlag: "tracking",
        roles: {
            isAdmin: true,
            isContributor: true
        }
    },
    children: [{
        path: "",
        name: "tracking",
        component: trackingIndex,
        meta: {
            basename: "tracking",
            featureFlag: "tracking",
            roles: {
                isAdmin: true,
                isContributor: true
            }
        }
    }]
}];
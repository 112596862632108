
import { baseRestService } from "@/services/_base";
import { Config } from "@/config";
import { boundariesStoreActions } from "@/store";

export class UsersInBoundariesService extends baseRestService {

    constructor() {
        super();
        this.baseUrl = Config.appServerUrl;
    }

    public async GetUsersInBoundaries(surveyid: string): Promise<server.userInBoundary[]> {
        let result = await this.Get<server.userInBoundary[]>(`/api/survey/${surveyid}/boundaries/users`);
        boundariesStoreActions.setUsersInBoundaries(result);
        return result;
    }

    public async GetUsersInBoundary(surveyid: string, boundaryId: string): Promise<server.userInBoundary[]> {
        let result = await this.Get<server.userInBoundary[]>(`/api/survey/${surveyid}/boundaries/${boundaryId}/users`);
        boundariesStoreActions.setUsersInBoundaries(result);
        return result;
    }

    public async GetBoundariesForUser(surveyid: string, userId: string): Promise<server.userInBoundary[]> {
        let result = await this.Get<server.userInBoundary[]>(`/api/survey/${surveyid}/team/${userId}/boundaries`);
        boundariesStoreActions.setUsersInBoundaries(result);
        return result;
    }

    public async SetUserToBoundary(surveyid: string, boundaryId: string, userId: string): Promise<server.userInBoundary> {
        let result = await this.Post<server.userInBoundary>(`/api/survey/${surveyid}/boundaries/${boundaryId}/user/${userId}`, []);
        boundariesStoreActions.setUsersInBoundaries([result]);
        return result;
    }

    public async DeleteUserFromBoundary(surveyid: string, boundaryId: string, userId: string): Promise<void> {
        let result = await this.Delete<void>(`/api/survey/${surveyid}/boundaries/${boundaryId}/user/${userId}`);
        boundariesStoreActions.deleteUserInBoundary({ boundaryId: boundaryId, userId: userId });
        return result;
    }
}

export const usersInBoundariesService = new UsersInBoundariesService();
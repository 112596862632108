import { baseRestService } from "./_base/baseRestService";
import { securityService } from "./securityService";
import { Deferred } from "./_base/Deferred";
import { Config } from "@/config";
import { surveysService } from "./surveyService";
import { surveysStoreGetters } from "@/store";

declare let $: any;

export class Hub {
  private _hub: any;
  private static _current: Hub;
  private static _initialized: Deferred<any>;
  private static _initializationCalls = new Array<Action>();

  static get Current(): Hub {

    if (!Hub._current) new Hub();

    return Hub._current;
  }

  static get Client() {
    return Hub._current._hub.client;
  }

  constructor() {
    Hub._current = this;
  }

  async isInitialized() {
    return await Hub._initialized.promise;
  }

  async initConnection() {
    this._hub = $.connection.communicationHub;
    this.setAuthorization();

    Hub._initializationCalls.forEach(call => {
      if (call)
        call(this._hub.client);
    });

    $.connection.hub.url = Config.appSignalrHub;
    // Token per signalr agganciato in modo esplicito


    $.connection.hub.disconnected(() => {
      // Hub.manageServerStatus("disconnected");
      console.log("SERVER CONNECTION: restart-connection")
      setTimeout(function () {
        Hub.Current.setAuthorization();
        $.connection.hub.start();
      }, 5000); // Restart connection after 5 seconds.
    });

    $.connection.hub.stateChanged((state) => {
      if (state.newState == $.signalR.connectionState.connected) {
        // Hub.manageServerStatus("connected");
      }
    });

    Hub._initialized = new Deferred();

    try {
      await $.connection.hub.start();
      Hub._initialized.resolve();
    } catch (err) {
      Hub._initialized.reject();
      console.error(err);
    }
  }

  async subscribeToMessage(action: Action) {
    Hub._initializationCalls.push(action);
  }

  private setAuthorization() {
    let authData = baseRestService.getAuthenticationToken();
    if (authData) {
      $.connection.hub.qs = {};
      $.connection.hub.qs.Authorization = 'Bearer ' + authData.access_token;
    }
  }

  async registerForSurvey() {
    await this.isInitialized();
    var surveyId = surveysStoreGetters.currentSurvey().id;
    this._hub.server.registerForSurvey(surveyId);
  }
}

export interface INotificationMessage {
  id: string;
  surveyId: string;
}

interface Action {
  (client);
}

import iziToast, { IziToastSettings, IziToastPosition } from "izitoast";
import { localizeFunction } from "src/filters/localize";

export class AlertHelper {

  public static showInfo(title: string, description: string, timeout: number | boolean = 5000) {
    try {
      iziToast.success({
        timeout,
        theme: "dark",
        title: localizeFunction(title),
        message: localizeFunction(description),
        class: "agr-white",
        icon: "fa fa-check fa-2x",
        position: "topLeft",
        progressBarEasing: "linear",
        progressBar: true,
        progressBarColor: "#f7386d",
        closeOnEscape: false,
        drag: false,
      } as any);
    } catch (err) {
      console.debug("show info error: ", err);
    }
  }

  public static showLoader(info: string) {
    const toast = document.querySelector("#toastLoading") as any;
    AlertHelper.operation++;

    if (toast) {
      try {
        iziToast.progress({}, toast).pause();
        iziToast.progress({}, toast).reset();
      } catch (err) {
        console.error(err);
      }
      return;
    }

    try {
      iziToast.info({
        id: "toastLoading",
        timeout: 1000,
        icon: "fas fa-circle-notch fa-spin fa-fw",
        position: "bottomLeft",
        toastOnce: true,
        theme: "dark",
        title: localizeFunction(info),
        close: false,
        width: 200,
        transitionIn: "fadeIn",
        transitionOut: "fadeOut",
        progressBarEasing: "linear",
        progressBar: true,
        progressBarColor: "#f7386d",
        closeOnEscape: false,
        drag: false,
        onClosed() {
          AlertHelper.operation = 0;
        },
      } as any);
    } catch (err) {
      // tslint:disable-next-line: no-console
      console.log(err);
    }
  }

  public static hideLoader() {
    setTimeout(() => {
      AlertHelper.operation--;
      const toast = document.querySelector("#toastLoading") as any;
      try {
        if (AlertHelper.operation < 1 && toast) {
          iziToast.progress({}, toast).resume();
        }
      } catch (err) {
        // tslint:disable-next-line: no-console
        console.log(err);
      }
    }, 500);
  }

  public static showError(title: string, description: string, timeout: number | boolean = 30000, position: IziToastPosition = "topLeft", onClosingCallback: any = null, onClosedCallback: any = null) {
    try {
      iziToast.error({
        timeout, theme: "dark",
        title: localizeFunction(title),
        message: description ? localizeFunction(description) : null,
        class: "agr-error",
        icon: "fa fa-exclamation-circle fa-2x",
        position,
        progressBarEasing: "linear",
        progressBar: true,
        progressBarColor: "#f7386d",
        closeOnEscape: false,
        drag: false,
        onClosing: onClosingCallback,
        onClosed: onClosedCallback,
      });
    } catch (err) {
      console.debug("show error", err);
    }
  }

  private static operation: number = 0;
}

const QualityIndex = () =>
    import ( /* webpackChunkName: "qualitycontrol" */ "./views/index.vue");

export const routes = [{
    path: "/quality",
    name: "quality",
    component: QualityIndex,
    meta: {
        basename: "quality",
        roles: {
            isAdmin: true,
            isContributor: true
        }
    }
}];
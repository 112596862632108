//lazy loading vue components
//https://router.vuejs.org/en/advanced/lazy-loading.html
//to make this work we also need the syntax-dynamic-import
//https://babeljs.io/docs/plugins/syntax-dynamic-import/

// layout
// import Full from "@/layouts/Full.vue";
const Full = () =>
    import ( /* webpackChunkName: "layout" */ "@/layouts/Full.vue");

// Base views
const Dashboard = () =>
    import ( /* webpackChunkName: "dashboard" */ "@/app_modules/dashboard/Dashboard.vue");

import AuthRedirect from "@/components/authRedirect/authRedirect";

function injectRoutes(mainRoute, childroutes) {
    for (const key in childroutes) {
        const element = childroutes[key];
        mainRoute[0].children.push(element);
    }
}

const routes = [{
        path: "/",
        redirect: "/dashboard",
        name: "Home",
        meta: {
            basename: 'dashboard',
            requireAuth: true,
            track: false,
            roles: {
                isAdmin: true,
                isOwner: true,
                isContributor: true
            }
        },
        component: Full,
        children: [{
            path: "dashboard",
            name: "dashboard",
            meta: {
                basename: 'dashboard',
                track: false,
                roles: {
                    isAdmin: true,
                    isOwner: true,
                    isContributor: true
                }
            },
            component: Dashboard
        }]
    },
    {
        path: "/authredirect",
        meta: { requireAuth: false, track: false },
        component: AuthRedirect
    }
];

// import { route as fieldRoutes } from "@/app_modules/fields_management/routes";
// injectRoutes(routes, fieldRoutes);

import { routes as boundariesRoute } from "@/app_modules/planner/routes";
import { routes as tasksRoute } from "@/app_modules/tasks/routes";
import { routes as usersRoute } from "@/app_modules/users/routes";
import { routes as issuesRoute } from "@/app_modules/issues/routes";
import { routes as logsRoute } from "@/app_modules/logs/routes";
import { routes as qualitycontrolRoute } from "@/app_modules/qualitycontrol/routes";
import { routes as trackingRoute } from "@/app_modules/tracking/routes";
import { routes as surveysRoute } from "@/app_modules/surveys/routes";
import { routes as exportRoute } from "@/app_modules/export/routes";

import { routes as surveyedItemsRoute } from "@/app_modules/surveyedItems/routes";
injectRoutes(routes, boundariesRoute);
injectRoutes(routes, tasksRoute);
injectRoutes(routes, usersRoute);
injectRoutes(routes, issuesRoute);
injectRoutes(routes, logsRoute);
injectRoutes(routes, qualitycontrolRoute);
injectRoutes(routes, trackingRoute);
injectRoutes(routes, surveysRoute);
injectRoutes(routes, exportRoute);
injectRoutes(routes, surveyedItemsRoute);

export default routes;

// Import dei moduli di default
import "@/components/map/module";
import { Esri } from "@/esriMap";
import { geojsonToArcGIS } from "@esri/arcgis-to-geojson-utils";
import { propertyResolve } from "@/store/UTILS";
import { boundariesStoreGetters, boundariesStoreActions } from "@/store/boundaries.store";
import { usersInBoundariesService, tasksService, boundariesService } from "@/services";
import { tasksStoreGetters } from "..";


export class BoundaryIntersection implements IBoundary {
  surveyId: any;
  parentId?: any;
  id: any;
  code: string;
  description: string;
  geoJSON: any;
  latitude: number;
  longitude: number;
  date: Date;
  children: number;
  selected: boolean;


  static polygonConstructor: __esri.PolygonConstructor = null;

  constructor() {
    this.Initialize();
  }

  async Initialize() {
    BoundaryIntersection.polygonConstructor = await Esri.Geometry.PolygonConstructor();
  }

  get users() {
    return propertyResolve(boundariesStoreGetters.getUsersInBoundary(this.id), () => usersInBoundariesService.GetUsersInBoundary(this.surveyId, this.id) as any, `${this.surveyId}_${this.id}`);
  }

  get tasks(): server.task[] {
    if (this.children > 0) return [];
    return propertyResolve(tasksStoreGetters.getTasks(this.id, null), () => tasksService.GetTasks(this.surveyId, this.id) as any, this.id);
  }
  
  get completed(): boolean {
    if (this.tasks.length === 0) {
      return false;
    }
    return this.tasks.every(t => t.status == server.TasksEnum.completed);
  }

  tree(): IBoundary[] {
    return propertyResolve(boundariesStoreGetters.getBoundaryTree(this.id), () => boundariesService.GetBoundaryTree(this.surveyId, this.id) as any, `boundary-tree-${this.id}`);
  }

  geometryESRI() {
    if (BoundaryIntersection.polygonConstructor && this.geoJSON)
      return new BoundaryIntersection.polygonConstructor(geojsonToArcGIS(this.geoJSON)) as any;
    return null;
  }
}

export const boundaryIntersection = new BoundaryIntersection();
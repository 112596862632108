import { CreateActionsHandler, CreateGettersHandler, fillArray } from './UTILS';

export interface LayersStoreModel {
  _layers: Map<string, server.layerFull>
  layers: server.layerFull[]
}

interface LayersStoreGetters { 
  getLayers(surveryId?: string): server.layerFull[];
  getLayer(surveryId: string, layerId: string): server.layerFull;
}
interface LayersStoreActions {
  setLayers(data: server.layerFull[]);
  deleteLayer(layerId: string);
}

export const layersStore = {
  PREFIX: "layers",
  namespaced: true,
  state: {
    _layers: new Map<string, server.layerFull>(),
    layers: []
  } as LayersStoreModel,
  getters: {
    getLayers: (state: LayersStoreModel) => (surveryId?: string) => {
      return state.layers.filter(w => !surveryId || w.surveyId == surveryId);
    },
    getLayer: (state: LayersStoreModel) => (surveryId: string, layerId: string) => {
      return state.layers.find(w => w.surveyId == surveryId && w.id == layerId);
    }
  },
  mutations: {
    setLayers(state: LayersStoreModel, data: server.layerFull[]) {
      if(!data || data.length <= 0) return;
      data.forEach(i => { state._layers.set(i.id, i); });
      fillArray(state.layers, state._layers.values());
    },
    deleteLayer(state: LayersStoreModel, layerId: string) {
      state._layers.delete(layerId);
      fillArray(state.layers, state._layers.values());
    }
  },
  actions: {
    setLayers(context, data: server.layerFull[]) {
      context.commit('setLayers', data);
    },
    deleteLayer(context, layerId: string) {
      context.commit('deleteLayer', layerId);
    }
  }
};

export const layersStoreActions = CreateActionsHandler<LayersStoreActions>(layersStore);
export const layersStoreGetters = CreateGettersHandler<LayersStoreGetters>(layersStore);

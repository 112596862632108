import Vue from "vue";
import md5 from "md5";

function gravatarFunction(email) {
  if (email)
    return "https://www.gravatar.com/avatar/" + md5(email)+ "?d=" + "identicon";
    
  return null;
}
Vue.filter("gravatar", gravatarFunction);

import { Esri } from "@/esriMap";
import { geojsonToArcGIS } from "@esri/arcgis-to-geojson-utils";
import { propertyResolve } from "@/store/UTILS";
import { boundariesStoreGetters, boundariesStoreActions } from "@/store/boundaries.store";
import { usersInBoundariesService, tasksService, boundariesService } from "@/services";
import { tasksStoreGetters } from "..";


export class TrackingIntersection implements ITracking {
  username: string;
  date: Date;
  latitude: number;
  longitude: number;
  jsonData: string;

  static pointConstructor: __esri.PointConstructor = null;

  constructor() {
    this.Initialize();
  }

  async Initialize() {
    TrackingIntersection.pointConstructor = await Esri.Geometry.PointConstructor();
  }

  geometryESRI() {
    if (TrackingIntersection.pointConstructor)
      return new TrackingIntersection.pointConstructor({ longitude: this.longitude, latitude: this.latitude, spatialReference: { wkid: 4326 } }  as __esri.PointProperties) as any;
    return null;
  }
}

export const trackingIntersection = new TrackingIntersection();
import { CreateActionsHandler, CreateGettersHandler } from "./UTILS";

export interface QualityStoreModel {
    qualityDefinitions: server.qualityDefinition[];
}

interface QualityStoreActions {
    setQualityDefinitons(items: server.qualityDefinition[]): void;
}

interface QualityStoreGetters {
    getQualityDefinitons(layerid?: string): server.qualityDefinition[];
}

export const qualityStore = {
    namespaced: true,
    PREFIX: "quality",
    state: {
        qualityDefinitions: [],
    },

    getters: {
        getQualityDefinitons: (state: QualityStoreModel) => (layerId?: string): server.qualityDefinition[] => {
            if (layerId) {
                return state.qualityDefinitions.filter((d) => d.layerId === layerId);
            }
            return state.qualityDefinitions;
        },
    },

    mutations: {
        setQualityDefinitons(state: QualityStoreModel, items: server.qualityDefinition[]) {
            items.forEach((item) => {
                const exists = state.qualityDefinitions.findIndex((q) => q.id === item.id);
                if (exists >= 0) {
                    state.qualityDefinitions.splice(exists, 1, item);
                } else {
                    state.qualityDefinitions.push(item);
                }
            });
        },
    },

    actions: {
        async setQualityDefinitons(context, items: server.qualityDefinition[]): Promise<void> {
            context.commit("setQualityDefinitons", items);
        },
    },
};

export const qualityStoreActions = CreateActionsHandler<QualityStoreActions>(qualityStore);
export const qualityStoreGetters = CreateGettersHandler<QualityStoreGetters>(qualityStore);

const ExportIndex = () =>
    import ( /* webpackChunkName: "exporting" */ "./index.vue");

export const routes = [{
    path: "/export",
    name: "export",
    component: ExportIndex,
    meta: {
        basename: "export",
        roles: {
            isAdmin: true,
            isOwner: true
        }
    }
}];
import { Config } from "@/config";
import { baseRestService, AuthToken, sharedInterceptors } from "./_base/baseRestService";

class LogsService extends baseRestService {

  constructor() {
    super();

    this.baseUrl = Config.appServerUrl;
  }

  public async GetLogs(surveyid: string, username?: string, entity?: string, action?: string, fromdate?: Date, todate?: Date, skip?: number, take?: number): Promise<server.eventLog[]> {
    let result = await this.Get<server.eventLog[]>(`/api/survey/${surveyid}/logs`, { username, entity, action, fromdate, todate, skip, take });
    return result;
  }

  public async CountLogs(surveyid: string, username?: string, entity?: string, action?: string, fromdate?: Date, todate?: Date): Promise<number> {
    let result = await this.Get<number>(`/api/survey/${surveyid}/logs/count`, { username, entity, action, fromdate, todate });
    return result;
  }

  public async GetFilterData(surveyid: string): Promise<server.eventFilterDatas> {
    let result = await this.Get<server.eventFilterDatas>(`/api/survey/${surveyid}/logs/filterdata`, null);
    return result;
  }
}


export const logsService = new LogsService();
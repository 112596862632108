import { MapHelper } from "@/app_modules/MapHelper";
import { Esri } from "@/esriMap";
import { localizeFunction } from "@/filters/localize";

export async function addOrtophotoTileLayer() {
  let tileLayer = await MapHelper.GetLayerById("ortophoto");
  if (tileLayer) {
    await MapHelper.removeLayer(tileLayer);
  }

  tileLayer = await Esri.Layers.WMTSLayer({
    id: "ortophoto",
    name: "ortophoto",
    title: "OrtoPhoto",
    legendEnabled: false,
    url: "https://moldova-map.md/bns/geoserver/gwc/service/wmts/",
    minScale: 4513.988705,
    maxScale: 282.124294,
    imageFormat: "jpg",
    activeLayer: {
      imageFormat: "image/jpeg",
      id: "BNS_background",
      tileMatrixSetId: "EPSG:900913", // Deve essere in Web mercatore
    },
  });

  MapHelper.addLayer(tileLayer, 0);
}

export async function addParcelFeatureLayer() {
  let tileLayer = await MapHelper.GetLayerById("parcel");
  if (tileLayer) {
    await MapHelper.removeLayer(tileLayer);
  }

  tileLayer = await Esri.Layers.FeatureLayer({
    id: "parcel",
    name: "parcel",
    title: localizeFunction("parcels", "Parcels"),
    legendEnabled: false,
    minScale: 4513.988705,
    maxScale: 282.124294,
    visible: true,
    url: "https://services.arcgis.com/ob2cwnTf90PVX8U4/arcgis/rest/services/Parcels/FeatureServer/0/query",
    renderer: {
      symbol: {
        color: "lightgreen",
        type: "simple-line",
        width: "2px",
      },
      type: "simple",
    },
  });

  MapHelper.addLayer(tileLayer, 1);
}
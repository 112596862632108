import { Config } from "@/config";
import { baseRestService, AuthToken, sharedInterceptors } from "./_base/baseRestService";
import { tasksStoreActions } from "@/store/tasks.store";

class TasksService extends baseRestService {
  constructor() {
    super();
    this.baseUrl = Config.appServerUrl;
  }

  public async CountTasks(surveyid: string, boundaryId? : string, userName?: string, state? : string, onlymine: boolean = false): Promise<number> {
    return await this.Get<number>(`/api/survey/${surveyid}/tasks/count`, { boundaryId, userName, state, onlymine });
  }

  public async GetTasks(surveyid: string, boundaryId? : string, userName?: string, state? : string, onlymine: boolean = false, skip?: number, take?: number): Promise<server.task[]> {
    const result = await this.Get<server.task[]>(`/api/survey/${surveyid}/tasks`, { boundaryId, userName, state, onlymine , skip, take });
    tasksStoreActions.setTasks(result);
    return result;
  }

  public async GetTask(surveyid: string, id: string): Promise<server.task> {
    const result = await this.Get<server.task>(`/api/survey/${surveyid}/tasks/${id}`);
    tasksStoreActions.setTasks([result]);
    return result;
  }

  public async PutTask(surveyid: string, task: server.task): Promise<server.task> {
    const result = await this.Put<server.task>(`/api/survey/${surveyid}/tasks/${task.id}`, task);
    tasksStoreActions.setTasks([result]);
    return result;
  }

  public async PostTask(surveyid: string, task: server.task): Promise<server.task> {
    const result = await this.Post<server.task>(`/api/survey/${surveyid}/tasks`, task);
    tasksStoreActions.setTasks([result]);
    return result;
  }

  public async deleteTask(surveyid: string, boundaryId: string, id: string): Promise<server.task> {
    const result = await this.Delete<server.task>(`/api/survey/${surveyid}/tasks/${id}`, { surveyid, boundaryId, id });
    tasksStoreActions.deleteTask(id);
    return result;
  }
}

export const tasksService = new TasksService();

const PlannerIndex = () =>
    import ( /* webpackChunkName: "planner" */ "./views/index.vue");

export const routes = [{
        path: "/planner/:boundaryId",
        name: "planner_boundary",
        component: PlannerIndex,
        props: true,
        meta: {
            basename: 'planner',
            roles: {
                isAdmin: true
            }
        }
    },
    {
        path: "/planner",
        name: "planner",
        component: PlannerIndex,
        meta: {
            basename: 'planner',
            roles: {
                isAdmin: true
            }
        }
    }
];
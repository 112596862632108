import { Config } from "@/config";
import { baseRestService, AuthToken, sharedInterceptors } from "./_base/baseRestService";
import { trackingStoreActions } from "@/store";

class TrackingService extends baseRestService {
    constructor() {
        super();
        this.baseUrl = Config.appServerUrl;
    }

    public async getUserTracking(surveyId: string, userId: string, fromDate: Date, toDate: Date = null): Promise<server.track[]> {
        let result = await this.Get<server.track[]>(`/api/survey/${surveyId}/tracking/${userId}`, { fromDate, toDate });
        trackingStoreActions.setTracking(result);
        return result;
    }


    public async getLastUserTracking(surveyId: string, userId: string): Promise<server.track> {
        return await this.Get<server.track>(`/api/survey/${surveyId}/tracking/${userId}/last`);
    }

}

export const trackingService = new TrackingService();

const IssuesIndex = () =>
    import ( /* webpackChunkName: "issues" */ "@/app_modules/issues/index.vue");

export const routes = [{
    path: "issues/",
    component: {
        template: "<router-view></router-view>",
    },
    meta: {
        basename: "issues:main",
        featureFlag: "issues",
        roles: {
            isAdmin: true,
            isContributor: true,
        },
    },
    children: [{
        path: ":issueId?",
        name: "issues",
        component: IssuesIndex,
        props: true,
        meta: {
            basename: 'issues',
            featureFlag: "issues",
            roles: {
                isAdmin: true,
                isContributor: true,
            },
        },
    }, ],
}, ];
import { isUndefined } from "util";
import { store } from "@/store";


/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///

class ResolverConfiguration {
  delayResolver: boolean = true;

  setFirstLoadingCompleted() {
    this.delayResolver = false;
  }
}




/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///

export const resolverConfiguration = new ResolverConfiguration();


/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
export const join = (obj) => (intersection) => {
  obj["__joins__"] = [];
  for (const k in intersection) {
    // if (!obj.hasOwnProperty(k)) {
    const propDescriptor = Object.getOwnPropertyDescriptor(intersection.__proto__, k);
    Object.defineProperty(obj, k, propDescriptor);
    obj["__joins__"].push(k);
    // }
  }
  return obj;
};


/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
const __pendingResolvers = new Map<any, Promise<any>>();



/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
export const propertyResolve = (getterValue, resolveFunction: (() => Promise<any>), key: any) => {

  if (key == null || (key instanceof Array && isEmptyArray(key.filter(i => i != null)))) return null;

  if (!resolverConfiguration.delayResolver)
    if (getterValue == null || isUndefined(getterValue) || isEmptyArray(getterValue)) {
      if (!__pendingResolvers.has(resolveFunction.toString() + (key || "").toString())) {
        __pendingResolvers.set(
          resolveFunction.toString() + (key || "").toString(),
          resolveFunction().then(() => removeResolver(resolveFunction, key), () => removeResolver(resolveFunction, key))
        );
      }
    }
  return getterValue || null;
};


/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
export function unjoin(obj: any): any {
  if (!obj) return null;
  if (!obj.hasOwnProperty("__joins__")) return obj;

  let newObj = {};
  for (const key in obj) {
    if (obj["__joins__"].indexOf(key) >= 0) continue;
    if (obj.hasOwnProperty(key)) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}


/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
function isEmptyArray(obj) {
  if (obj && obj.constructor === Array && obj.length == 0) return true;
  return false;
}

/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
function removeResolver(resolveFunction, key) {

  // I resolver NON devono essere rimossi
  return;
}


/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
export function fillArray<T>(dest: T[], source: Iterable<T>): T[] {
  const sourceArr = Array.from(source);
  const toremove: T[] = [];

  dest.forEach((element) => {
    if (sourceArr.indexOf(element) < 0) { toremove.push(element); }
  });

  toremove.forEach((element) => {
    const idx = dest.indexOf(element);
    dest.splice(idx, 1);
  });

  sourceArr.forEach((element) => {
    if (dest.indexOf(element) < 0) { dest.push(element); }
  });

  return dest;
}


/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
export function hasFeature(featureId) {
  return store.getters["features/hasFeature"](featureId);
}


/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
export function CreateActionsHandler<T>(store: { PREFIX: string, actions: any }) {
  return new Proxy({}, StoreActionProxyHandler(store.PREFIX, store.actions)) as T;
}

/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
export function CreateGettersHandler<T>(store: { PREFIX: string, actions: any }) {
  return new Proxy({}, StoreGetterProxyHandler(store.PREFIX, store.actions)) as T;
}

/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
const StoreActionProxyHandler = (PREFIX, actions: any) => {
  let actionsNames = Object.getOwnPropertyNames(actions);
  return {
    get: (target, key) => {
      return function (...rest) {
        // console.debug("dispatching action:", PREFIX + "/" + key, ...rest);
        return store.dispatch(PREFIX + "/" + key, ...rest);
      };
    },
    ownKeys() {
      return [...actionsNames, "prototype"];
    },
    getPrototypeOf(target) {
      return target;
    }
  };
};

/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
const StoreGetterProxyHandler = (PREFIX: string, getters: any) => {
  let gettersNames = Object.getOwnPropertyNames(getters);
  return {
    get: (target, key) => {
      return function (...args) {
        if (!store.getters[PREFIX + "/" + key]) {
          throw `getter "${PREFIX}/${key}" is not registered in the store... maybe the store module is not namespaced?`
        }
        //console.debug("getting getter:", store, PREFIX + "/" + key, store.getters[PREFIX + "/" + key], ...args);
        return store.getters[PREFIX + "/" + key](...args);
      };
    },
    ownKeys() {
      return [...gettersNames, "prototype"];
    },
    getPrototypeOf(target) {
      return target;
    }
  };
};

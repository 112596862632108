const CreateStart = () => import(/* webpackChunkName: "surveyWizard" */ "./createStart.vue");
const CreateBoundaries = () => import(/* webpackChunkName: "surveyWizard" */ "./createBoundaries.vue");
const CreateLayers = () => import(/* webpackChunkName: "surveyWizard" */ "./createLayers.vue");
const QualityControlDefinitions = () => import(/* webpackChunkName: "surveyWizard" */ "./qualityControlDefinitions.vue");
const MonitoringDefinitions = () => import(/* webpackChunkName: "surveyWizard" */ "./monitoringDefinitions.vue");

export const createRoutes = [
    {
        path: "start",
        name: "surveys:create:start",
        meta: {
            alias: "survey_create",
            track: true,
            globalFilter: false,
        },
        component: CreateStart,
    },
    {
        path: "boundaries",
        name: "surveys:create:boundaries",
        meta: {
            alias: "add_boundaries",
            track: true,
            globalFilter: false,
        },
        component: CreateBoundaries,
    },
    {
        path: "layers",
        name: "surveys:create:layers",
        meta: {
            alias: "add_layers",
            track: true,
            globalFilter: false,
        },
        component: CreateLayers,
    },
    {
        path: "quality-definitions",
        name: "surveys:create:quality-definitions",
        meta: {
            alias: "manage_quality_definitions",
            track: true,
            globalFilter: false,
        },
        component: QualityControlDefinitions,
    },
    {
        path: "monitoring-definitions",
        name: "surveys:create:monitoring-definitions",
        meta: {
            alias: "manage_monitoring_definitions",
            track: true,
            globalFilter: false,
        },
        component: MonitoringDefinitions,
    },
];

export const editRoutes = [
    {
        path: "start",
        name: "surveys:edit:start",
        meta: {
            alias: "survey_edit",
            track: true,
            globalFilter: false,
        },
        component: CreateStart,
    },
    {
        path: "layers",
        name: "surveys:edit:layers",
        meta: {
            alias: "manage_layers",
            track: true,
            globalFilter: false,
        },
        component: CreateLayers,
    },
    {
        path: "quality-definitions",
        name: "surveys:edit:quality-definitions",
        meta: {
            alias: "manage_quality_definitions",
            track: true,
            globalFilter: false,
        },
        component: QualityControlDefinitions,
    },
    {
        path: "monitoring-definitions",
        name: "surveys:create:monitoring-definitions",
        meta: {
            alias: "manage_monitoring_definitions",
            track: true,
            globalFilter: false,
        },
        component: MonitoringDefinitions,
    },
];
export class FactoryService {

  private static _objects: { [key: string]: any } = {};

  public static Register<T>(ctor: { new(): T }) {
    let name = FactoryService.getTypeName<T>(ctor);
    FactoryService._objects[name] = new ctor();
  }

  public static RegisterExplicit(name: string, ctor: { new(): any }) {
    FactoryService._objects[name] = new ctor();
  }


  public static GetByName(name:string): any{
    return FactoryService._objects[name];
  }

  public static SetByName(name:string, object:any){
    FactoryService._objects[name] = object;
  }

  public static Get<T>(ctor: { new(): T }): T {
    let result;
    let name = FactoryService.getTypeName<T>(ctor);
    result = FactoryService._objects[name] as T;
    if (result == null) {
      result = FactoryService._objects[name] = new ctor();
    }
    return result as T;
  }

  public static GetExplicit<T>(name: string): T {
    return FactoryService._objects[name] as T;
  }

  private static getTypeName<T>(ctor: { new(): T }): string {
    return ctor.name;
  }

  private static createInstance<T>(ctor: { new(): T }): T {
    return new ctor();
  }
}

export function Service<T>(ctor: { new(): T }): T {
  return FactoryService.Get(ctor) as T;
}

export function ServiceDeclare<T extends { new(): {} }>(ctor: T) {
  FactoryService.RegisterExplicit(ctor.name, ctor);
}

export interface INamedObject {
  _$typename: string;
}

import Vue from "vue";
import VueRouter from "vue-router";
import { Component } from "vue-property-decorator";

import routes from "@/router/routes";
import { securityService } from "@/services";
import { usersStoreGetters } from "@/store";

declare var CLIENT_ID: string;

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteUpdate",
  "beforeRouteLeave",
]);

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 } as any),
  routes,
});

router.beforeEach(async (to, from, next) => {

  // if the route requires authentication and the user is not authenticated, start the sign in process
  const isUserAuthenticated = await securityService.isUserAuthenticated();

  // se è richiesto un featureflag ma non ho il flag attivo.
  // if(to.matched.some(record => record.meta.featureFlag && !FeatureHelper.HasFeatureSync( record.meta.featureFlag))) next(false);
  const currentUser = usersStoreGetters.currentUser();

  if (to.matched.some((record) => record.meta.requireAuth) && !isUserAuthenticated) {
    next(false);
    await securityService.startSignIn();
  } else {
    if (to.matched.some((record) =>
      record.meta.roles &&
      !((record.meta.roles.isAdmin && currentUser.isAdmin) ||
        (record.meta.roles.isContributor && currentUser.isContributor) ||
        (record.meta.roles.isMobileUser && currentUser.isMobileUser) ||
        (record.meta.roles.isOwner && currentUser.isOwner)
      ))
    ) {
      next(false);
      await securityService.Logout();
    } else {
      next();
    }
  }
});

export default router;

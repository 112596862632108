const Index = () =>
    import ( /* webpackChunkName: "surveys" */ "@/app_modules/surveys/index.vue");
const Create = () =>
    import ( /* webpackChunkName: "surveys" */ "@/app_modules/surveys/views/surveyCreate.vue");
const Edit = () =>
    import ( /* webpackChunkName: "surveys" */ "@/app_modules/surveys/views/surveyEdit.vue");
const Clone = () =>
    import ( /* webpackChunkName: "surveys" */ "@/app_modules/surveys/views/surveyClone.vue");
const Detail = () =>
    import ( /* webpackChunkName: "surveys" */ "@/app_modules/surveys/views/surveyDetail.vue");

import { createRoutes as wizCreateRoutes } from "./wizard/routes.ts";
import { editRoutes as wizEditRoutes } from "./wizard/routes.ts";

export const routes = [{
        path: "/surveys",
        name: "surveys",
        meta: {
            basename: "surveys",
            roles: {
                isAdmin: true
            },
            track: false,
        },
        component: Index,
        children: [],
    },
    {
        name: "surveys:create",
        path: "/surveys/create",
        meta: {
            basename: "surveys",
            roles: {
                isAdmin: true
            },
            track: false,
            globalFilter: false,
        },
        component: Create,
        children: wizCreateRoutes,
    },
    {
        name: "surveys:edit",
        path: "/surveys/edit/:surveyId",
        meta: {
            basename: "surveys",
            roles: {
                isAdmin: true
            },
            track: false,
            globalFilter: false,
        },
        props: true,
        component: Edit,
        children: wizEditRoutes,
    },
    {
        name: "surveys:detail",
        path: "/surveys/detail/:surveyId",
        meta: {
            basename: "surveys",
            roles: {
                isAdmin: true
            },
            track: false,
            globalFilter: false,
        },
        props: true,
        component: Detail,
    },
    {
        name: "surveys:clone",
        path: "/surveys/clone/:surveyId",
        meta: {
            basename: "surveys",
            roles: {
                isAdmin: true
            },
            track: false,
            globalFilter: false,
        },
        props: true,
        component: Clone,
        children: wizEditRoutes,
    },
];
import Vue from "vue";
import * as pf from "sprintf-js";
import { getStoreState, localizationStoreGetters } from "src/store";

Vue.filter("localize", localizeFunction);

export function localizeFunction(translationKey, ...args) {

  if (!translationKey) {
    return "null";
  }

  translationKey = translationKey.toLowerCase();

  if (!getStoreState || !getStoreState().localization) { return translationKey; }

  const value = localizationStoreGetters.getLocalizedLabel(translationKey, "default");

  if (args && args.length > 0) { return pf.vsprintf(value || translationKey, args); }
  return value || translationKey;
}

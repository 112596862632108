import { trackingService } from "@/services";
import { Esri } from "@/esriMap";
import { surveysStoreGetters } from "..";

export class UserTeamIntersection implements IUserTeam {
    public id: any;
    public userName: string;
    public description: string;
    public boundaries: string[];
    public isAdmin: boolean;
    public isContributor: boolean;
    public isMobileUser: boolean;
    public isOwner: boolean;

    public async lastPosition(): Promise<__esri.Point> {
        const lastTrack = await trackingService.getLastUserTracking(surveysStoreGetters.currentSurvey().id, this.id);
        if (!lastTrack) {
            return null;
        }

        return await Esri.Geometry.Point({
            longitude: lastTrack.longitude,
            latitude: lastTrack.latitude,
            spatialReference: { wkid: 4326 },
        } as __esri.PointProperties);
    }

}

export const userTeamIntersection = new UserTeamIntersection();

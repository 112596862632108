import Vue from "vue";
import Component from "vue-class-component";
import { securityService } from "@/services";
import { Config } from "@/config";

@Component({})
export default class AuthRedirect extends Vue {

  public async created() {
    securityService.checkAuthenticationResponse();

    try {
      await securityService.getUserInfo();
    } catch {
      // le autenticazioni non funzionano..
      // esco e trono al flow normale.
      window.location.hash = Config.clientSideUrl;
    }

    const nextRoute = await securityService.getUserRequestedPath();
    if (nextRoute) {
      window.location.href = nextRoute;
    } else {
      this.$router.push({ name: "Home" });
    }
  }
}

import { baseRestService } from "./_base";
import { Config } from "@/config";
import { usersStoreActions } from "@/store";

class UserService extends baseRestService {

    constructor() {
        super();
        this.baseUrl = Config.appServerUrl;
    }

    public async CountUsers(surveyid: string, username?: string): Promise<number> {
        return await this.Get<number>(`/api/survey/${surveyid}/team/count`, { username });
    }

    public async GetUsers(surveyid: string, username?: string, skip?: number, take?: number): Promise<server.userTeam[]> {
        const result = await this.Get<server.userTeam[]>(`/api/survey/${surveyid}/team`, { username, skip, take });
        if (result && result.length > 0) { usersStoreActions.setUsers(result); }
        return result;
    }

    public async InviteUser(surveyid: string, model): Promise<server.userTeam> {
        const result = await this.Post<server.userTeam>(`/api/survey/${surveyid}/team/invite`, model);
        if (result) { usersStoreActions.setUser(result); }
        return result;
    }

    public async SetUserData(surveyid: string, model: server.userTeam): Promise<server.userTeam> {
        const data = {
            id: model.id,
            surveyid,
            description: model.description,
            isAdmin: model.isAdmin,
            isContributor: model.isContributor,
            isMobileUser: model.isMobileUser,
            isOwner: model.isOwner,
        }
        const result = await this.Put<server.userTeam>(`/api/survey/${surveyid}/team/${model.id}`, data);
        if (result) { usersStoreActions.setUser(result); }
        return result;
    }

    public async GetCurrentUserSurvey(surveyid: string, username: string, noStored: boolean = false): Promise<server.userTeam> {
        const result = await this.Get<server.userTeam>(`/api/survey/${surveyid}/team/survey`, { username });
        if (result && !noStored) {
            usersStoreActions.setCurrentUser(result);
            usersStoreActions.setUser(result);
        }
        return result;
    }

    public async DeleteUser(surveyId: string, userId: string): Promise<boolean> {
        const result = await this.Delete<boolean>(`/api/survey/${surveyId}/team/${userId}`);
        if (result) { usersStoreActions.removeUser(userId); }
        return result;
    }

    public async SetUserPassword(surveyId: string, userId: string, password: string, confirmPassword: string) {
        return await this.Post<boolean>(`/api/survey/${surveyId}/team/${userId}/setpassword`, { surveyId, userId, password, confirmPassword });
    }
}

export const userService = new UserService();

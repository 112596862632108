import { CreateActionsHandler, CreateGettersHandler, join, fillArray } from "./UTILS";
import { surveyIntersection } from "./intersections/surveys.intersection";

export interface SurveysStoreModel {
  _surveys: Map<string, ISurvey>;
  surveys: ISurvey[];
  currentSurvey: ISurvey;
}

interface SurveysStoreGetters {
  currentSurvey(): ISurvey;
  getSurveys(): ISurvey[];
  getSurvey(id: string): ISurvey;
}
interface SurveysStoreActions {
  setSurveys(data: server.survey[]);
  deleteSurvey(surveyId);
  setCurrentSurvey(data: ISurvey);
}

export const surveysStore = {
  PREFIX: "surveys",
  namespaced: true,
  state: {
    _surveys: new Map<string, ISurvey>(),
    surveys: [],
    currentSurvey: JSON.parse(window.localStorage.getItem("current-survey")),
  } as SurveysStoreModel,
  getters: {
    getSurveys: (state: SurveysStoreModel) => () => {
      return state.surveys;
    },
    getSurvey: (state: SurveysStoreModel) => (id: string) => {
      return state.surveys.find((f) => f.id === id);
    },
    currentSurvey: (state: SurveysStoreModel) => () => {
      return state.currentSurvey;
    },
  },
  mutations: {
    setSurveys(state: SurveysStoreModel, data: server.survey[]) {
      if (!data || data.length <= 0) {
        return;
      }
      data.filter((x) => !!x).forEach((i) => { state._surveys.set(i.id, join(i)(surveyIntersection)); });
      fillArray(state.surveys, state._surveys.values());
      if (!state.currentSurvey || (state.surveys.map(x => x.id).indexOf(state.currentSurvey.id) === -1)) {
        state.currentSurvey = state.surveys[0];
      }
    },
    deleteSurvey(state: SurveysStoreModel, surveyId: string) {
      state._surveys.delete(surveyId);
      fillArray(state.surveys, state._surveys.values());
    },
    setCurrentSurvey(state: SurveysStoreModel, data: ISurvey) {
      window.localStorage.setItem("current-survey", JSON.stringify(data));
      state.currentSurvey = data;
    },
  },
  actions: {
    setSurveys(context, data: server.survey[]) {
      context.commit("setSurveys", data);
    },
    deleteSurvey(context, surveyId: number) {
      context.commit("deleteSurvey", surveyId);
    },
    setCurrentSurvey(context, data: ISurvey) {
      context.commit("setCurrentSurvey", data);
    },
  },
};

export const surveysStoreActions = CreateActionsHandler<SurveysStoreActions>(surveysStore);
export const surveysStoreGetters = CreateGettersHandler<SurveysStoreGetters>(surveysStore);

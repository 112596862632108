import { baseRestService } from "@/services/_base";
import { Factory } from "@/services/_base";
import { Config } from "@/config";
import { localizationStoreActions, getStoreState } from "@/store";

export class LocalizationService extends baseRestService {

  get currentLanguage() { return getStoreState().localization.currentLanguage; }
  set currentLanguage(value: string) {
    window.localStorage.setItem("current-language", value);

    this.getLabels(value).then(r => {
      localizationStoreActions.changeLanguage({ newLanguage: value, labels: r });

    });
  }

  public supportedLanguages: String[];

  constructor() {
    super();

    this.baseUrl = Config.localizationServerUrl;
    this.currentLanguage = window.localStorage.getItem("current-language") || (navigator.language || (navigator as any).userLanguage).substring(0, 2) || "handshaking";
  }

  async handshake(): Promise<void> {
    const result = await this.Get<server.localization.handshake>("/api/localization/handshake");
    // if(this.currentLanguage == "handshaking")
    //   this.currentLanguage = result.current;

    this.supportedLanguages = result.supported;

    if (this.supportedLanguages.indexOf(this.currentLanguage) < 0) {
      this.currentLanguage = result.current;
    }
    localizationStoreActions.setSupportedLanguages(result.supported);
  }

  async getLabels(lang?: string): Promise<server.localization.label[]> {
    if (!lang) {
      lang = this.currentLanguage;
    }
    return await this.Get<server.localization.label[]>("/api/localization/" + lang + "/labels");
  }

  async setLabel(key: string, value: string, group?: string, lang?: string): Promise<server.localization.label> {
    if (!lang) {
      lang = this.currentLanguage;
    }

    const obj = { stringCode: key, stringValue: value, group: group || "agr_fe_commons", languageCode: lang } as server.localization.label;
    const result = await this.Post<server.localization.label>("/api/localization/" + this.currentLanguage + "/labels", obj);
    return result;
    // return null;
  }

  async getTranslations(key: string): Promise<any> {
    const params = {
      stringCode: key,
    };
    const result = await this.Get<any>("/api/localization/translations", params);
    return result;
  }

  async saveTranslations(data): Promise<any> {
    await this.Post<any>("/api/localization/translations", data);
  }
}

export const localizationService = new LocalizationService();
